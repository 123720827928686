import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthManager implements CanActivate {

    constructor(private auth: AuthService) { }

    private claims: any;
    private loadingClaims: boolean = false;

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

        return new Promise<boolean>(resolve => {

            this.auth.autentica()
                .catch(() => resolve(false))
                .then(() => {

                    this.hasClaim('entrevista.Acesso', ['Sim'])
                        .catch(() => resolve(false))
                        .then(() => {

                            const expectedResource = (route.data as any).resource;
                            if (expectedResource != undefined) {
                                this.checkAccess(expectedResource)
                                    .catch(() => resolve(false))
                                    .then(() => resolve(true));
                            } else {
                                resolve(true);
                            }
                        });
                });
        });
    }

    checkAccess(resource: any): Promise<boolean> {

        return new Promise<boolean>((resolve, reject) => {

            let promise: Promise<boolean> = null;

            switch (resource) {
                case 'home':
                    promise = this.hasClaim('entrevista.Acesso', ['Sim']);
                    break;
                case 'importacao':
                    promise = this.hasClaim('entrevista.perfil',['Administrador', 'RH1','RH2']);              
                    break;
                case 'importacaomanual':
                    promise = this.hasClaim('entrevista.perfil', ['Administrador', 'RH2']);
                    break;
                case 'exfuncionario':
                    promise = this.hasClaim('entrevista.perfil', ['Administrador', 'RH2', 'Gestor']);
                    break;  
                case 'relatorios':
                    promise = this.hasClaim('entrevista.perfil', ['Administrador', 'RH2']);
                    break;                 
            }

            promise
                .then(() => resolve(true))
                .catch(() => reject(false));

        });
    }

    public hasClaim(claim: string, values: string[]): Promise<boolean> {
        values.push('Administrador');
        if (this.loadingClaims) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    this.hasClaim(claim, values)
                        .then(() => resolve(true))
                        .catch(() => reject(false));
                }, 100);
            });
        }

        return new Promise<boolean>((resolve, reject) => {

            if (this.claims) {
                values.indexOf(this.claims[claim]) > -1 ? resolve(true) : reject(false);
            } else {
                this.loadingClaims = true;
                this.auth.getClaims().then(claims => {
                    this.claims = claims;
                    this.loadingClaims = false;
                    values.indexOf(claims[claim]) > -1 ? resolve(true) : reject(false);
                });
            }
        });
    }
}