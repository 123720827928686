import { JsonProperty } from "json-object-mapper";
import { SelectorBase } from "./base/base.selector";

export class TipoTratativaSelector extends SelectorBase {
    @JsonProperty({name: 'tipoTratativa'})
    public tipoTratativa: string = '';

    @JsonProperty({name: 'obrigaArquivo'})
    public obrigaArquivo: boolean = false;

    @JsonProperty({name: 'obrigaChamado'})
    public obrigaChamado: boolean = false;
}
