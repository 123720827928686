import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './_core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ToastrModule } from 'ngx-toastr';
import { LoadingService } from './services/loading.service';

import { PagesModule } from './pages/pages.module';
import { AuthInterceptor } from './services/base/auth.interceptor';
import { ServicesModule } from './services/services.module';
import { LoadingModule, ANIMATION_TYPES } from 'ngx-loading';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData } from '@angular/common';
import localeDECH from '@angular/common/locales/pt';
import { HeaderComponent } from './components/header/header.component';

registerLocaleData(localeDECH);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    BsDropdownModule.forRoot(),

    PagesModule,
    HttpClientModule,
    ServicesModule,
    LoadingModule.forRoot({
      animationType: ANIMATION_TYPES.rectangleBounce
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    })
  ],
  providers: [
    LoadingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID, useValue: 'pt-BR',
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
