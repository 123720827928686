import { Injectable } from '@angular/core';
import { HttpClient } from "../_core/http.cliente";
import { ServiceBase } from './base/base.service';
import { DivergenciaSelector } from '../selectors/divergencia.selector';
import { DivergenciaDomain } from '../domain/divergencia.domain';
import { Observable } from 'rxjs';
import { TipoDesligamentoSelector } from '../selectors/tipodesligamento.selector';
import { TipoDesligamentoDomain } from '../domain/tipodeligamento.domain';


@Injectable()
export class TipoDesligamentoService extends ServiceBase<TipoDesligamentoSelector, TipoDesligamentoDomain> {

  constructor(http: HttpClient) {
    super('TipoDesligamento', http);
  }

  createEntity(input: any): TipoDesligamentoDomain {      
    return TipoDesligamentoDomain.map(input);
  }  

}
