import { JsonProperty, JsonIgnore, ObjectMapper } from 'json-object-mapper';
import { SelectorBase } from './base/base.selector';
import { ImportacaoDomain } from '../domain/importacao.domain';

export class ImportacaoSelector extends SelectorBase {

    @JsonProperty({name: 'matricula_solicitante'})
    public matriculaSolicitante: string = '';

    @JsonProperty({name: 'solicitante'})
    public solicitante: string = '';
    
     @JsonProperty({name: 'arquivo'})
     public arquivo: string = '';

     @JsonProperty({name: 'nome_arquivo'})
     public nomeArquivo: string = '';

    @JsonProperty({name: 'status'})
    public status: string = '';

    @JsonProperty({name: 'data-importacao'})
    public dataImportacao: string = '';

    @JsonIgnore()
    public statuslist: string[] = [];

    // @JsonIgnore()
    // public datelist: string[] = [];

    @JsonProperty({name: 'dtinicio'})
    public dtinicio: string = '';
    
    @JsonProperty({name: 'dtfim'})
    public dtfim: string = '';
        
    @JsonIgnore()
    public datelist: Date[] = [new Date("2019-01-01 00:00:00"), new Date()];
    private _readDates(){
        
        if (this.datelist.length) {
            this.dtinicio = `${this.datelist[0].getMonth() + 1}/${this.datelist[0].getDate()}/${this.datelist[0].getFullYear()}`;
            this.dtfim = `${this.datelist[1].getMonth() + 1}/${this.datelist[1].getDate()}/${this.datelist[1].getFullYear()}`;
        } else {
            this.dtinicio =  '';
            this.dtfim =  '';
        }
    }

    public toJSON(): string {
        
        this._readDates();
        return <string>ObjectMapper.serialize(this);
    }

}