import { Injectable } from '@angular/core';

//import { HttpClientModule } from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';

@Injectable()
export class HttpClient {

    private entPoint: string;

    private obToken: Observable<Headers>;

    constructor(private http: Http, private auth: AuthService) {

        this.entPoint = environment.ApiUrl;

        this.obToken = new Observable(observer => {
            this.auth.getToken().then(token => {
                let headers = new Headers();
                headers.append('Authorization', 'Bearer ' + token);
                headers.append('Content-type', 'application/json');
                observer.next(headers);
                observer.complete();
            });
        });
    }

    get EntPoint() {
        return this.entPoint;
    }

    get(url, headers: any = {}): Observable<any> {

        return new Observable<any>(observer => {

            this.obToken.subscribe(header => {
                this.http.get(this.entPoint + url, {
                    headers: Object.assign(header, headers)
                }).pipe(
                    map(response => {
                        return response;
                    })
                ).subscribe(response => {
                   observer.next(response);
                   observer.complete();
               });
            });
        });
    }

    getReport(url): Observable<any> {

        return new Observable<any>(observer => {

            this.obToken.subscribe(header => {
                this.http.get(this.entPoint + url, {
                    headers: header
                }).subscribe(response => {
                    observer.next(response);
                    observer.complete();
                });
            });
        });
    }

    getWithParams(url, params): Observable<any> {

        return new Observable<any>(observer => {

            this.obToken.subscribe(header => {
                this.http.get(this.entPoint + url, {
                    headers: header,
                    params: { seletor: params }
                })
                .pipe(
                    map(response => {
                        return response;
                    })
                ).subscribe(response => {
                    observer.next(response);
                    observer.complete();
                });
            });
        });
    }

    post(url, data): Observable<any> {
        return new Observable<any>(observer => {

            this.obToken.subscribe(header => {
                this.http.post(this.entPoint + url, data, {
                    headers: header
                }).pipe(
                    map(response => {
                        return response;
                    })
                ).subscribe(response => {
                    observer.next(response);
                    observer.complete();
                });
            });
        });
    }

    put(url, data): Observable<any> {

        return new Observable<any>(observer => {

            this.obToken.subscribe(header => {
                this.http.put(this.entPoint + url, data, {
                    headers: header
                 }).pipe(
                    map(response => {
                        return response;
                    })
                 ).subscribe(response => {
                    observer.next(response);
                    observer.complete();
                });
            });
        });
    }
    delete(url): Observable<any> {
        return new Observable<any>(observer => {

            this.obToken.subscribe(header => {
                this.http.delete(this.entPoint + url, {
                    headers: header
                }).pipe(
                    map(response => {
                        return response;
                    })
                ).subscribe(response => {
                    observer.next(response);
                    observer.complete();
                 });
            });
        });
    }
}