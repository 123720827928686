import { Injectable } from '@angular/core';
import { HttpClient } from "../_core/http.cliente";
import { ServiceBase } from './base/base.service';
import { Observable } from 'rxjs';
import { RelatorioSelector } from '../selectors/relatorio.selector';
import { RelatorioDomain } from '../domain/relatorio.domain';

@Injectable()
export class RelatorioService extends ServiceBase<RelatorioSelector, RelatorioDomain> {

  constructor(http: HttpClient) {
    super('relatorio', http);
  }

  solicitar(selector: RelatorioSelector): Observable<any>{
    return this.Http.post(this.EndPoint + '/solicitar', selector.toJSON());
  }

  createEntity(input: any): RelatorioDomain {    
    return RelatorioDomain.map(input);
  }

}