import { Component, OnInit } from '@angular/core';
import { ListAbstract } from 'src/app/services/base/list.service';
import { ImportacaoManualDomain } from 'src/app/domain/importacaoManual.domain';
import { ImportacaoManualSelector } from 'src/app/selectors/importacaoManual.selector';
import { ImportacaoService } from 'src/app/services/importacao.service';
import { User } from 'src/app/domain/user.domain';
import { ImportacaoSelector } from 'src/app/selectors/importacao.selector';
import { ImportacaoDomain } from 'src/app/domain/importacao.domain';
import { FormGroup } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from 'src/app/services/loading.service';
import { DivergenciaService } from 'src/app/services/divergencia.service';
import { BsLocaleService, defineLocale, ptBrLocale } from 'ngx-bootstrap';
import { ExFuncionarioDomain } from 'src/app/domain/exfuncionario.domain';
import { ExFuncionarioSelector } from 'src/app/selectors/exfuncionario.selector';
import { ExFuncionarioService } from 'src/app/services/exfuncionario.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-importacaomanual-list',
  templateUrl: './importacaomanual-list.page.html',
  styleUrls: ['./importacaomanual-list.page.sass']
})
export class ImportacaomanualListPage extends ListAbstract<ExFuncionarioDomain, ExFuncionarioSelector, ExFuncionarioService> implements OnInit {
  [x: string]: any;

  pagina: number = 1;
  closeCollapse: boolean = false;

  bsConfig: { containerClass: string; };
  user: User;
  arquivo: string;
  nomeArquivo: string;
  matricula: string;
  solicitante: string;
  matriculaExColaborador: string;
  dataImportacao: string;
  public tipoImportacao: string;
  public justificativa: string;

  public formTratamentoGroup: FormGroup;
  constructor(
    private _exfuncionarioService: ExFuncionarioService,
    private _user: UserService,
    private _toastService: ToastrService,
    private _loadingService: LoadingService,
    private _importacaoService: ImportacaoService,
    private _localeService: BsLocaleService
  ) {
    super(_exfuncionarioService, new ExFuncionarioSelector())
    this.loading = _loadingService;
    this.toast = _toastService;
  }

  ngOnInit() {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-orange' });
    defineLocale('pt-br', ptBrLocale);
    this._localeService.use('pt-br');
  //  this._exfuncionarioService.list(new ExFuncionarioSelector).subscribe(async x => {
   //   let arr = this.Seletor.removeDuplicate(await x.Data.map(x => x.tipoImportacao));
   //   this.Seletor.tipoImportacaolist = arr;
      //this.Seletor.datelist = x.Data.map(x => x.dataImportacao);
   //   this._loadingService.close()
   // });
   // this.filter();
  }


  filter() {
    this.query();
  }

  baixarAnexo(item) {
    var matricula = item.matricula;
    this._importacaoService.baixarAnexo(matricula).subscribe(res => {
      if (res._body == "arquivo não encontrado!") {
        this._toastService.error("Error", "Arquivo não encontrado!")
      } else {
        var file = res.json().data.nome_arquivo;
        window.open(`${environment.ApiUrl}upload/${file}`, '_blank');
      }
    }, error => {
      console.log(error);
      this._toastService.error("Error", "Ops ocorreu um error ao tentar baixar o anexo!")
    })
  }

}
