import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { DomainBase } from "./base/base.domain";

export class TipoTratativaDomain extends DomainBase {
    @JsonProperty({name: 'tipo_tratativa'})
    public tipoTratativa: string = '';

    @JsonProperty({name: 'obriga_arquivo'})
    public obrigaArquivo: boolean = false;

    @JsonProperty({name: 'obriga_chamado'})
    public obrigaChamado: boolean = false;
   
    public static map(object: any): TipoTratativaDomain {
        let obj = ObjectMapper.deserialize(this, object);
        return obj;
    }
}
