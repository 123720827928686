import { EnvironmentBase } from './enviroment.base';

export const environment: EnvironmentBase = {
    production: true,
    ApiUrl: 'https://api.entrevista.redeconecta.net.br/',
    SSOUrl: 'https://identidade.redeconecta.net.br/identity',
    redirectUri: 'https://entrevista.redeconecta.net.br',
    ScopesSso: 'openid profile entrevista entrevista.api dadosgestao.api auth.api',
    clientId: 'entrevista',
    fileDirectory: 'https://api.entrevista.redeconecta.net.br/upload/recebidos/'    
  };
