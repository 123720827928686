import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from "../../app-routing.module";
import { EntrevistasPage } from './entrevistas.page';
import { UserService } from 'src/app/services/user.service';
import { ExFuncionarioService } from 'src/app/services/exfuncionario.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule, PaginationModule, BsDatepickerModule } from 'ngx-bootstrap';
//import { Angular5Csv } from 'angular5-csv/Angular5-csv';
//import { NgxLoadingModule } from 'ngx-loading';
import { EntrevistasListPage } from './list/entrevistas-list.page';
import { EntrevistasFormPage } from './form/entrevistas-form.page';
import {NgxPaginationModule} from 'ngx-pagination';
import { FormAprendizPage } from './form/form-aprendiz/form-aprendiz.page';
import { FormColaboradorPage } from './form/form-colaborador/form-colaborador.page';


@NgModule({
    declarations: [
        EntrevistasPage,
        EntrevistasListPage,
        EntrevistasFormPage,
        FormAprendizPage,
        FormColaboradorPage,
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        NgxPaginationModule,
        //Angular5Csv,
        //NgxLoadingModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        BsDatepickerModule.forRoot()
    ],
    exports: [
        EntrevistasPage
    ],
    providers: [
        UserService,
        ExFuncionarioService
    ],
})
export class EntrevistasModule { }
