import { NgModule } from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";
import { AppRoutingModule } from "../../app-routing.module";
import { ImportacaomanualPage } from './importacaomanual.page';
import { UserService } from 'src/app/services/user.service';
import { ImportacaoService } from 'src/app/services/importacao.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule, PaginationModule, BsDatepickerModule } from 'ngx-bootstrap';
//import { Angular5Csv } from 'angular5-csv/Angular5-csv';
import { LoadingModule } from 'ngx-loading';
import { ImportacaomanualListPage } from './list/importacaomanual-list.page';
import { ImportacaomanualFormPage } from './form/importacaomanual-form.page';
import { TipoDesligamentoService } from 'src/app/services/tipodesligamento.service';
import { ExFuncionarioService } from 'src/app/services/exfuncionario.service';
import localePt from '@angular/common/locales/pt';
import { NgxPaginationModule } from 'ngx-pagination';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
    declarations: [
        ImportacaomanualPage,
        ImportacaomanualListPage,
        ImportacaomanualFormPage
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        //Angular5Csv,
        NgxPaginationModule,
        LoadingModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        BsDatepickerModule.forRoot()
    ],
    exports: [
        ImportacaomanualPage
    ],
    providers: [
        UserService,
        ImportacaoService,
        TipoDesligamentoService,
        ExFuncionarioService
    ],
})
export class ImportacaoManualModule { }
