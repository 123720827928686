import { Component, OnInit, TemplateRef } from "@angular/core";
import { ExFuncionarioService } from "src/app/services/exfuncionario.service";
import { ExFuncionarioSelector } from "src/app/selectors/exfuncionario.selector";
import { ExFuncionarioDomain } from "src/app/domain/exfuncionario.domain";
import { StatusService } from "src/app/services/status.service";
import { StatusSelector } from "src/app/selectors/Status.seletor";
import { ListAbstract } from "src/app/services/base/list.service";
import { ToastrService } from "../../../../../node_modules/ngx-toastr";
import { LoadingService } from "src/app/services/loading.service";
import { UserService } from "src/app/services/user.service";
import { User } from "src/app/domain/user.domain";
import { SelectorBase } from "src/app/selectors/base/base.selector";
import { Profile } from "selenium-webdriver/firefox";

import {
  BsModalService,
  BsLocaleService,
  ptBrLocale,
  defineLocale,
  BsModalRef
} from "ngx-bootstrap";
import { TipoDesligamentoDomain } from "../../../domain/tipodeligamento.domain";
import { TipoDesligamentoService } from "../../../services/tipodesligamento.service";
import { TipoDesligamentoSelector } from "../../../selectors/tipodesligamento.selector";
import { TipoTratativaService } from "src/app/services/tipotratativa.service";
import { TipoTratativaSelector } from "src/app/selectors/tipotratativa.selector";
import { TipoTratativaDomain } from "src/app/domain/tipotratativa.domain";

@Component({
  selector: "app-Entrevistasmanual-list",
  templateUrl: "./entrevistas-list.page.html",
  styleUrls: ["./entrevistas-list.page.sass"]
})
export class EntrevistasListPage
  extends ListAbstract<
    ExFuncionarioDomain,
    ExFuncionarioSelector,
    ExFuncionarioService
  >
  implements OnInit {
  user: User;
  pagina: number = 1;
  closeCollapse: boolean = true;
  modalRef: BsModalRef;
  justificativa_preenchida: boolean = false;
  touched: boolean = false;
  justificativa: string;

  num_chamado: string = '';
  just_tratativa: string = '';
  id_tipo: number = 0;
  TiposTratativa: TipoTratativaDomain[] = [];
  arquivo: string = '';
  nomeArquivo: string = '';
  tipoArquivo: string = '';

  mensagem: string = 'Use os filtros para carregar os dados';

  public tipoDesligamento: number;
  public tiposDesligamento: TipoDesligamentoDomain[] = [];

  constructor(
    private _entrevistasService: ExFuncionarioService,
    private _toastService: ToastrService,
    private _loadingService: LoadingService,
    private _tipoDesligamento: TipoDesligamentoService,
    private _user: UserService,
    private _modalService: BsModalService,
    private _tipoTratativa: TipoTratativaService
  ) {
    super(_entrevistasService, new ExFuncionarioSelector());
    this.loading = _loadingService;
    this.toast = _toastService;
  }

  public EntrevistasFiltroList: any;

  ngOnInit() {
    this._tipoDesligamento.list(new TipoDesligamentoSelector()).subscribe(x => {
      this.tiposDesligamento = x.Data;
    });
    this._user
      .getUser()
    .then(user => {
      this.user = user;
      console.log(this.user.profile);
    })
    .then(() => {
      this.carregarTodas();
      //this.filter();
    });
  }

  filter() {
    this.Seletor.entrevistaPerfilGestor = this.user.profile;
    this.Seletor.colaboradorLogadoId = this.user.username;
    this.Seletor.id = -1;
    this.query();
    this.mensagem = 'Nenhum dado encontrado'
  }

  habilitar(selecionado) {
    this.List.find(x => x.id == selecionado).habilitado = true;
    this.Seletor.id = selecionado;
    this.Seletor.colaboradorLogadoId = this.user.username;
    this.Seletor.colaboradorLogadoNome = this.user.fullName;
    //this.Seletor.entrevistaPerfilGestor = this.user.profile;
    this._entrevistasService.habilitar(this.Seletor).subscribe(x => {
      this._toastService.success("", "Entrevista habilitada com sucesso");
    });
  }

  desabilitar(selecionado) {
    this.List.find(x => x.id == selecionado).habilitado = false;
    this.Seletor.id = selecionado;
    this.Seletor.colaboradorLogadoId = this.user.username;
    this.Seletor.colaboradorLogadoNome = this.user.fullName;
    this.Seletor.tipoDesligamento = this.tipoDesligamento;
    this._entrevistasService.desabilitar(this.Seletor).subscribe(x => {
      this._toastService.success("", "Entrevista Desabilitada com sucesso");
      this.Seletor = new ExFuncionarioSelector();
      this.modalRef.hide();
    });
  }

  openModal(template: TemplateRef<any> = null) {
    this._loadingService.show();
    this.Seletor.colaboradorLogadoId = this.user.username;
    this.Seletor.colaboradorLogadoNome = this.user.fullName;
    // this.Seletor.justificativa = exfuncionario.justificativa;
    // this.Seletor.matricula = exfuncionario.matricula;
    this._entrevistasService.list(this.Seletor).subscribe(x => {
      this.List = x.Data.map(x => x);
      if (template != null) {
        this.modalRef = this._modalService.show(template, {
          class: "modal-lg"
        });
      }
      this._loadingService.close();
    });
  }

  checkJustificativa() {
    this.touched = true;
    if (this.Seletor.justificativa_log != "") {
      this.justificativa_preenchida = true;
    } else {
      this.justificativa_preenchida = false;
    }
  }
  carregarTodas() {
    this._loadingService.show();
    var seletor = new ExFuncionarioSelector();
    seletor.rowsForPage = 9999;
    seletor.entrevistaPerfilGestor = this.user.profile;
    seletor.colaboradorLogadoId = this.user.username;

    this._entrevistasService.carregarCentrosCusto(seletor).subscribe(
      x => {
        let arr = JSON.parse(x._body);
        this.Seletor.centrocustolist = arr.data;
        this._loadingService.close();
      }
    );

    this._entrevistasService.carregarStatus().subscribe(
      x => {
        let arr = JSON.parse(x._body);
        this.Seletor.statusList = arr.data.map(y => {
          return {
            status_id:  y.id,
            ds_descricao: y.ds_descricao
          }
        });
      }
    );

    /*this._entrevistasService.list(seletor).subscribe(
      async x => {
        let arr = this.Seletor.removeDuplicate(
          await x.Data.map(x => x.centroCusto)
        );
        this.Seletor.centrocustolist = arr;

        let arr2 = this.Seletor.removeDuplicate(await x.Data.map(x => x.nome));
        this.Seletor.funcionarioslist = arr2;

        // this._entrevistasService.list(seletor).subscribe(async x => {
        // let arr3 = this.Seletor.removeDuplicate(await x.Data.map(x => x.id_status_entrevista && x.ds_descricao));
        // let arr4 = this.Seletor.removeDuplicate(await x.Data.map(x => x.ds_descricao));
        //   let arr3 = []
        //   let arr4 = []
        //   x.Data.forEach((data)=> {
        //     if(!arr3.includes(data.id_status_entrevista)){
        //       arr3.push(data.id_status_entrevista)
        //       arr4.push(data.ds_descricao)
        //     }
        //   })
        //  for(let i =0; i <arr3.length; i++) {
        //    this.Seletor.statusList.push({
        //      status_id: arr3[i],
        //      ds_descricao: arr4[i]
        //    })
        //  }
        x.Data[0].status_list.forEach(status => {
          this.Seletor.statusList.push({
            status_id: status.id,
            ds_descricao: status.descricao
          });
        });

        this._loadingService.close();
        // })
      },
      err => {
        this._loadingService.close();
      }
    );*/
  }

  openModalAjuste(template: TemplateRef<any> = null) {
    if (template != null) {
      this.modalRef = this._modalService.show(template, {
        class: "modal-sm"
      });
    }
  }

  autorizarAjusteManual(selecionado){
    this.List.find(x => x.id == selecionado).fl_ajustar_manual = true;
    this.Seletor.id = selecionado;
    this.Seletor.colaboradorLogadoId = this.user.username;
    this.Seletor.colaboradorLogadoNome = this.user.fullName;
    this._entrevistasService.autorizarManual(this.Seletor).subscribe(x => {
      this._toastService.success("", "Ajuste manual autorizado com sucesso");
      this.modalRef.hide();
    });
  }

  openModalTratamento(template: TemplateRef<any> = null){
    this._loadingService.show();
    let seletor = new TipoTratativaSelector();
    this._tipoTratativa.list(seletor).subscribe(x => {
      this.TiposTratativa = x.Data.map(x => x);
      if (template != null) {
        this.modalRef = this._modalService.show(template, {
          class: "modal-xl"
        });
      }
      this._loadingService.close();
    });
  }

  fileChange(event, input: any) {
    if (event.target.files[0].size > 31684744) {
      input.value = '';
      alert('O arquivo selecionado é muito grande');
      return
    }

    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      if(file.type == 'application/pdf' || file.type == 'image/png' || file.type == 'image/bmp' || file.type == 'image/jpeg' ){
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => { 
          this.arquivo = (reader.result as string).split(',')[1];
          this.tipoArquivo = file.type;
          this.nomeArquivo = file.name;
        };
        debugger
      }else{
        alert('Apenas arquivos do tipo PDF, JPEG e BMP');
      }
    }
  }

  removeArquivo() {    
    this.arquivo = null;
    this.tipoArquivo = null;
    this.nomeArquivo = null;
  }

  voltar() {
    this.modalRef.hide();
    this.arquivo = null;
    this.tipoArquivo = null;
    this.nomeArquivo = null;
    this.just_tratativa = '';
    this.id_tipo = 0;
  }

  salvarTratativa(selecionado){
    if (this.just_tratativa == "") {
      this._toastService.error("", "Justificativa obrigatória");
    } else if (this.id_tipo < 1) {
      this._toastService.error("", "Tipo obrigatório");
    } else if (this.id_tipo == 8 && this.num_chamado == '') {
      this._toastService.error("", "Número do chamado obrigatório");
      //Verificar se tem o arquivo
    } else {
      this._loadingService.show();
      //let selector = new ExFuncionarioSelector();
      this.Seletor.id = selecionado;
      this.Seletor.just_tratativa = this.just_tratativa;
      this.Seletor.tipo_tratativa_id = this.id_tipo;
      this.Seletor.arquivo = this.arquivo;
      this.Seletor.tipoArquivo = this.tipoArquivo;
      this.Seletor.nomeArquivo = this.nomeArquivo;
      if(this.num_chamado != '') this.Seletor.num_chamado = this.num_chamado;
      this._entrevistasService.tratarManual(this.Seletor).subscribe(x => {
        this._toastService.success("", "Tratativa realizada com sucesso");
        this.List.find(x => x.id == selecionado).respondido = true;
        this.modalRef.hide();
        this._loadingService.close();
      });
    }
  }
}
