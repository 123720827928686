import { JsonProperty, JsonIgnore, ObjectMapper } from "json-object-mapper";
import { SelectorBase } from "./base/base.selector";
import { ImportacaoDomain } from "../domain/importacao.domain";
import { ExFuncionarioDomain } from "../domain/exfuncionario.domain";
import { StatusDomain } from "../domain/Status.Domain";

export class ExFuncionarioSelector extends SelectorBase {
  @JsonProperty({ name: "matricula" })
  public matricula: string = "";

  @JsonProperty({ name: "matricula_solicitante" })
  public matriculaSolicitante = "";

  @JsonProperty({ name: "nome" })
  public nome: string = "";

  @JsonProperty({ name: "cpf" })
  public cpf: string = "";

  @JsonProperty({ name: "gestor" })
  public gestor: string = "";

  @JsonProperty({ name: "empresa" })
  public empresa: string = "";

  @JsonProperty({ name: "uf" })
  public uf: string = "";

  @JsonProperty({ name: "centro_custo" })
  public centroCusto: string = "";

  @JsonProperty({ name: "justificativa" })
  public justificativa: string = "";

  @JsonProperty({ name: "justificativa_log" })
  public justificativa_log: string = "";

  @JsonProperty({ name: "tipo_importacao" })
  public tipoImportacao: string = "";

  @JsonProperty({ name: "tipo_desligamento" })
  public tipoDesligamento: number = 0;

  @JsonProperty({ name: "id_status_entrevista" })
  public id_status_entrevista: number = 0;

  @JsonProperty({ name: "data_importacao" })
  public dataImportacao: string = "";

  @JsonIgnore()
  public tipoImportacaolist: string[] = [];

  @JsonIgnore()
  public funcionarioslist: string[] = [];

  @JsonIgnore()
  public centrocustolist: string[] = [];

  @JsonProperty({ name: "dtinicio" })
  public dtinicio: string = "";

  @JsonProperty({ name: "dtfim" })
  public dtfim: string = "";

  @JsonProperty({ name: "respondido" })
  public respondido: boolean = null;

  @JsonProperty({ name: "habilitado" })
  public habilitado: boolean = null;

  @JsonProperty({ name: "colaborador_logado_id" })
  public colaboradorLogadoId: string = "";

  @JsonProperty({ name: "ds_descricao" })
  public ds_descricao: string = "";

  @JsonProperty({ name: "colaborador_logado_nome" })
  public colaboradorLogadoNome: string = "";

  @JsonProperty({ name: "entrevista_perfil_gestor" })
  public entrevistaPerfilGestor: string = "";

  @JsonProperty({ name: "cd_colaborador" })
  public cd_colaborador: string = "";

  @JsonProperty({ name: "fl_ajustar_manual" })
  public fl_ajustar_manual: boolean = null;

  @JsonProperty({ name: "tipo_tratativa_id" })
  public tipo_tratativa_id: number = 0;

  @JsonProperty({ name: "num_chamado" })
  public num_chamado: string = "";

  @JsonProperty({ name: "just_tratativa" })
  public just_tratativa: string = "";

  @JsonProperty({name: "arquivo"})
  public arquivo: string = "";

  @JsonProperty({name: "nome_arquivo"})
  public nomeArquivo: string = "";

  @JsonProperty({name: "tipo_arquivo"})
  public tipoArquivo: string = "";

  @JsonIgnore()
  public statusList: {
    status_id: number;
    ds_descricao: string;
  }[] = [];

  @JsonIgnore()
  public descricaoList: string[] = [];

  @JsonIgnore()
  public datelist: Date[] = [new Date("2019-01-01 00:00:00"), new Date()];
  private _readDates() {
    if (this.datelist.length) {
      this.dtinicio = `${this.datelist[0].getMonth() +
        1}/${this.datelist[0].getDate()}/${this.datelist[0].getFullYear()}`;
      this.dtfim = `${this.datelist[1].getMonth() +
        1}/${this.datelist[1].getDate()}/${this.datelist[1].getFullYear()}`;
    } else {
      this.dtinicio = "";
      this.dtfim = "";
    }
  }

  public toJSON(): string {
    this._readDates();
    return <string>ObjectMapper.serialize(this);
  }
}
