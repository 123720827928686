import { JsonProperty, JsonIgnore, ObjectMapper } from 'json-object-mapper';
import { SelectorBase } from './base/base.selector';
import { ImportacaoDomain } from '../domain/importacao.domain';
import { ExFuncionarioDomain } from '../domain/exfuncionario.domain';

export class ImportacaoManualSelector extends SelectorBase {

    @JsonProperty({name: 'matricula'})
    public matricula: string = '';

    @JsonProperty({name: 'nome'})
    public nome: string = '';
    
    @JsonProperty({name: 'matricula_ex_func'})
    public matriculaExFunc = '';

    @JsonProperty({name: 'arquivo'})
    public arquivo: string = '';

    @JsonProperty({name: 'nome_arquivo'})
    public nomeArquivo: string = '';

    @JsonProperty({name: 'tipo_arquivo'})
    public tipoArquivo: string = '';

    @JsonProperty({name: 'justificativa'})
    public justificativa: string = '';

    @JsonProperty({name: 'tipo_importacao'})
    public tipoImportacao: string = '';

    @JsonProperty({name: 'tipo_desligamento'})
    public tipoDesligamento: number = 0;
  
    @JsonProperty({name: 'data_importacao'})
    public dataImportacao: string = '';

    @JsonIgnore()
    public statuslist: string[] = [];

    // @JsonIgnore()
    // public datelist: string[] = [];

    @JsonProperty({name: 'dtinicio'})
    public dtinicio: string = '';
    
    @JsonProperty({name: 'dtfim'})
    public dtfim: string = '';
        
    @JsonIgnore()
    public datelist: Date[] = [new Date(new Date().setDate(new Date().getDate()-1)), new Date()];
   // public datelist: Date[] = [new Date("2019-01-01 00:00:00"), new Date()];
    private _readDates(){
        
        if (this.datelist.length) {
            this.dtinicio = `${this.datelist[0].getMonth() + 1}/${this.datelist[0].getDate()}/${this.datelist[0].getFullYear()}`;
            this.dtfim = `${this.datelist[1].getMonth() + 1}/${this.datelist[1].getDate()}/${this.datelist[1].getFullYear()}`;
        } else {
            this.dtinicio =  '';
            this.dtfim =  '';
        }
    }

    public toJSON(): string {
        
        this._readDates();
        return <string>ObjectMapper.serialize(this);
    }

}