import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthManager } from './auth.manager';

@Directive({
  selector: '[hasClaim]'
})
export class HasClaimDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private securityService: AuthManager
  ) { }

  @Input()
  set hasClaim(claimType: any) {
    const data = claimType as string[];
    const claim = data[0];
    data.splice(0, 1);
    const values = data;

    this.securityService.hasClaim(claim, values)
      .then(() => {
        this.viewContainer.createEmbeddedView(this.templateRef);
      })
      .catch(() => {
        this.viewContainer.clear();
      });
  }
}