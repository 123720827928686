import { Injectable } from '@angular/core';
import { HttpClient } from "../_core/http.cliente";
import { ServiceBase } from './base/base.service';
import { Observable } from 'rxjs';
import { ImportacaoDomain } from '../domain/importacao.domain';
import { ImportacaoSelector } from '../selectors/importacao.selector';
import { EmployeeClientDomain } from '../domain/EmployeeClient.domain';
import { ImportacaoManualDomain } from '../domain/importacaoManual.domain';
import { ImportacaoManualSelector } from '../selectors/importacaoManual.selector';
import { AnexoDomain } from '../domain/anexo.domain';
import { ResponseContentType } from '@angular/http';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Response } from '../domain/response.domain';


@Injectable()
export class ImportacaoService extends ServiceBase<ImportacaoSelector, ImportacaoDomain> {

  constructor(http: HttpClient) {
    super('importacao', http);
  }

  createEntity(input: any): ImportacaoDomain {    
    return ImportacaoDomain.map(input);
  }

  importar(selector: ImportacaoSelector): Observable<ImportacaoDomain> {   
    return this.Http.post(this.EndPoint, selector.toJSON());
  }

  importarManual(selector: ImportacaoManualSelector): Observable<any> {  
    //debugger 
    return this.Http.post(this.EndPoint + '/importacaomanual', selector.toJSON());
  }

  download(selector: ImportacaoSelector): Observable<any>{  
    return this.Http.post(this.EndPoint + '/download', selector.toJSON());
  }

  buscarSubordinados(matricula): Observable<any>{
   // debugger
     var matriculagestor = matricula;
    // var nome = nome;
     return this.Http.get(this.EndPoint + '/getsubordinado'+`/${matricula}`);
   // return this.Http.get(this.EndPoint + '/getsubordinados/0100000723/Rafael');
    // .map(x => 
    //   this.createEntity(x.data != null ? x.data : new ImportacaoDomain())
    //   );
  }

  buscarDiretoriaGerencia(matricula): Observable<EmployeeClientDomain>{
    return this.Http.get(this.EndPoint + '/getdiretoriagerencia' + `/${matricula}`);
  }


  // baixarAnexo(matricula): Observable<Blob>{
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type':  'application/pdf',
  //       //'Authorization' : this.authKey,
  //       responseType : 'blob' as 'json',
  //       Accept : 'application/pdf',
  //       observe : 'response'
  //     })
  //   };
  // //   let options = {
  // //     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  // //     responseType: 'blob' as 'json'
  // // };
  //   return this.Http.get(this.EndPoint + '/baixaranexo' + `/${matricula}`, httpOptions)
  //   .pipe( 
  //        map(res => {
  //          debugger
  //          var file = new Blob([res._body], { type: 'application/pdf' });
  //          return file;
  //      })
  //   ) 
  // }

   baixarAnexo(matricula): Observable<AnexoDomain>{ 
    return this.Http.get(this.EndPoint + '/baixaranexo' + `/${matricula}`);     
  }
}
