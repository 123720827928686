import { UserService } from './../../services/user.service';
import { User } from './../../domain/user.domain';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_core/auth.service';
import { Injectable } from '@angular/core';

@Injectable()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  title: string = 'Entrevista de Desligamento';
   userProfile: User;

  constructor(
    private _user: UserService,
    private _auth: AuthService,

  ) { }

  ngOnInit() {
    this._user.getUser().then( user => {
      this.userProfile = user;
    });
  }

  logout(): void {
    this._auth.logout();
  }

}
