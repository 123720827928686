import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-importacao',
  templateUrl: './importacao.page.html',
  styleUrls: ['./importacao.page.sass']
})
export class ImportacaoPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
