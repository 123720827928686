import { HomePage } from './home/home.page';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingService } from '../services/loading.service';
import { HomeModule } from './home/home.module';
import { ImportacaoModule } from './importacao/importacao.module';
import { ImportacaoManualModule } from './importacaomanual/importacaomanual.module';
import { EntrevistasModule } from './entrevistas/entrevistas.module';
import { RelatoriosModule } from './relatorios/relatorios.module';


@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        ImportacaoModule,
        ImportacaoManualModule,
        EntrevistasModule,
        RelatoriosModule
    ],
    exports: [
        HomeModule,
        ImportacaoModule,
        ImportacaoManualModule,
        EntrevistasModule,
        RelatoriosModule
    ],
    providers: [
        LoadingService
    ],
})
export class PagesModule {}