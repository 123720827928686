import { Component, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
import { LoadingService } from "src/app/services/loading.service";
import { BsLocaleService, defineLocale, ptBrLocale } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ListAbstract } from "src/app/services/base/list.service";
import { RelatorioSelector } from "src/app/selectors/relatorio.selector";
import { RelatorioService } from "src/app/services/relatorio.service";
import { RelatorioDomain } from "src/app/domain/relatorio.domain";
import { TipoDesligamentoService } from "src/app/services/tipodesligamento.service";
import { TipoDesligamentoSelector } from "src/app/selectors/tipodesligamento.selector";
import { ExFuncionarioService } from "src/app/services/exfuncionario.service";

@Component({
  selector: "app-relatorios-list",
  templateUrl: "./relatorios-list.page.html",
  styleUrls: ["./relatorios-list.page.sass"]
})
export class RelatoriosListPage
  extends ListAbstract<RelatorioDomain, RelatorioSelector, RelatorioService>
  implements OnInit {
  bsConfig: { containerClass: string };

  constructor(
    private _relatorioService: RelatorioService,
    private _loadingService: LoadingService,
    private _localeService: BsLocaleService,
    private _toastService: ToastrService,
    private _tipoDesligamentoService: TipoDesligamentoService,
    private _exFuncionarioService: ExFuncionarioService
  ) {
    super(_relatorioService, new RelatorioSelector());
    this.loading = _loadingService;
    this.toast = _toastService;
  }

  ngOnInit() {
    this.bsConfig = Object.assign({}, { containerClass: "theme-orange" });
    defineLocale("pt-br", ptBrLocale);
    this._localeService.use("pt-br");
    this.carregarMotivos();
  }

  carregarMotivos() {
    this._tipoDesligamentoService
      .list(new TipoDesligamentoSelector())
      .subscribe(x => {
        this.Seletor.motivosList = x.Data;
      });
  }

  solicitar() {
    this._loadingService.show();

    if (!this.VerificarDatas()) {
      this._toastService.error(
        "Permitidos períodos de até 6 meses",
        "Datas Inválidas"
      );
    } else {
      var datePipe = new DatePipe(this._localeService.currentLocale);
      var hoje = datePipe.transform(this.Seletor.today, "dd-MM-yyyy");

      this.Service.solicitar(this.Seletor).subscribe(
        async x => {
          let response = await JSON.parse(x._body);
          if (response.data.length > 0) {
            this.downloadCSV({
              data: response.data,
              filename: `entrevistas-${hoje}.csv`
            });
            this._loadingService.close();
          } else {
            this._toastService.error(
              "",
              "Nenhum dado encontrado com os filtros selecionados"
            );
            this._loadingService.close();
          }
        },
        err => {
          this._loadingService.close();
          this._toastService.error("", err.error);
        }
      );
    }
  }

  public convertArrayOfObjectsToCSV(args) {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;
    var filter = args.filter || [""];

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = ";";
    lineDelimiter = "\r";

    keys = Object.keys(data[0]);

    result = "";
    keys.forEach(function(key) {
      if (!filter.includes(key)) {
        result += key.toUpperCase() + "" + columnDelimiter;
      }
    });
    // result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function(item) {
      ctr = 0;
      keys.forEach(function(key) {
        //if (ctr > 0) result += columnDelimiter;
        if (!filter.includes(key)) {
          if (item[key] != null) {
            if (typeof item[key] == "object") {
              if (item[key].length != 0) {
                result += item[key].descricao.toUpperCase();
                result += columnDelimiter;
              }
            } else {
              var a = item[key].toString();
              result += a.toUpperCase();
              result += columnDelimiter;
            }
          } else {
            result += " ";
            result += columnDelimiter;
          }
        }
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  downloadCSV(data, filename = "entrevista") {
    let csvData = this.convertArrayOfObjectsToCSV(data);
    console.log(csvData);
    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;"
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  // public downloadCSV(args) {
  //   var data, filename, link;
  //   var csv = this.convertArrayOfObjectsToCSV({
  //       data: args.data
  //   });
  //   if (csv == null) return;

  //   filename = args.filename || 'export.csv';

  //   if (!csv.match(/^data:text\/csv/i)) {
  //     csv = 'data:text/csv;charset=utf-8,\uFEFF' + csv;
  //   }

  //   data = encodeURI(csv);

  //   link = document.createElement('a');
  //   link.setAttribute('href', data);
  //   link.setAttribute('download', filename);
  //   link.click();
  // }

  public VerificarDatas() {
    var datasValidas = true;

    var mesfinal = this.Seletor.datelist[1].getMonth();
    var mesinicial = this.Seletor.datelist[0].getMonth();
    var diafinal = this.Seletor.datelist[1].getDate();
    var diainicial = this.Seletor.datelist[0].getDate();

    if (mesfinal - mesinicial > 6) {
      datasValidas = false;
    } else if (mesfinal - mesinicial == 6) {
      if (diafinal - diainicial > 0) {
        datasValidas = false;
      }
    }

    return datasValidas;
  }
}
