import { UserService } from './user.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DivergenciaService } from './divergencia.service';

@NgModule({
    declarations: [],
    imports: [ CommonModule ],
    exports: [],
    providers: [
        UserService,
        DivergenciaService
    ],
})
export class ServicesModule {}