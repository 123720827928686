import { environment } from '../../environments/environment';
import { JsonProperty, ObjectMapper, JsonIgnore } from 'json-object-mapper';
import { DomainBase } from "./base/base.domain";
import { PerguntaDomain } from './pergunta.domain';
import { RespostaDomain } from './resposta.domain';

export class RespostaPerguntaDomain extends DomainBase {

    @JsonProperty({name: 'id_pergunta'})
    public idPergunta: number = 0;

    @JsonProperty({name: 'id_resposta'})
    public idResposta: number = 0;

    @JsonProperty({name: 'id_ex_funcionario'})
    public idExFuncionario: number = 0;

    @JsonProperty({name: 'descricao_justificativa'})
    public descricao: string = '';

    @JsonProperty({name: 'pergunta', type: PerguntaDomain})
    public pergunta: PerguntaDomain = new PerguntaDomain();

    @JsonProperty({name: 'resposta', type: RespostaDomain})
    public resposta: RespostaDomain = new RespostaDomain();

    public static map(object: any): RespostaPerguntaDomain {
        let obj = ObjectMapper.deserialize(this, object);
        return obj;
    }

    public static mapArray(object: any): RespostaPerguntaDomain[] {
        return ObjectMapper.deserializeArray(this, object);
    }
}