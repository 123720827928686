import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExFuncionarioDomain } from 'src/app/domain/exfuncionario.domain';
import { ExFuncionarioService } from 'src/app/services/exfuncionario.service';
import { FormAbstract } from 'src/app/_core/form-abstract';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'form-colaborador',
  templateUrl: './form-colaborador.page.html',
  styleUrls: ['./form-colaborador.page.sass']
})
export class FormColaboradorPage extends FormAbstract<ExFuncionarioDomain, ExFuncionarioService> implements OnInit {

  @Input() entrevista: ExFuncionarioDomain;

  //public entrevista: ExFuncionarioDomain = new ExFuncionarioDomain();
  public formEntrevista: FormGroup;
  public entrevistas: ExFuncionarioDomain[] = [];

  public finalForm: string = "Entrevista respondida pelo colaborador"

  constructor(
    private _entrevistasService: ExFuncionarioService,
    private _toastService: ToastrService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private _loadingService: LoadingService
  ) {
    super(_entrevistasService, activeRoute, router, _toastService);

    this.formEntrevista = new FormGroup({
      'matricula': new FormControl({value: '',disable: true}),
      'nome': new FormControl({value: '',disable: true}),
      'dataAdmissao': new FormControl({value: '',disable: true}),
      'cargo': new FormControl({value: '',disable: true}),
      'dataDemissao': new FormControl({value: '',disable: true}),
      'gerente': new FormControl({value: '',disable: true}),
      'telefoneGerente': new FormControl({value: '',disable: true}),
      'gestorImediato': new FormControl({value: '',disable: true}),
      'telefoneGestor': new FormControl({value: '',disable: true}),
      'centroCusto': new FormControl({value: '',disable: true}),
      'grupoHirarquico': new FormControl({value: '',disable: true}),
      'uf': new FormControl({value: '',disable: true}),
      'localAtuacao': new FormControl({value: '',disable: true}),
      // 'respondeu': new FormControl({value: '',disable: true}),
      // 'deficiencia': new FormControl({value: '',disable: true}),
      // 'especifique': new FormControl({value: '',disable: true}),
      'tempoEmpresa': new FormControl({value: '',disable: true}),
      'motivoDesligamento': new FormControl({value: '',disable: true}),
      'obsMotivo': new FormControl({value: '',disable: true}),
      'novaEmpresa': new FormControl({value: '',disable: true}),
      'novoCargo': new FormControl({value: '',disable: true}),
      'processoSeletivo': new FormControl({value: '',disable: true}),
      'just_processoSeletivo': new FormControl({value: '',disable: true}),
      'part_treinamento': new FormControl({value: '',disable: true}),
      'just_part_treinamento': new FormControl({value: '',disable: true}),
      'bem_recebido': new FormControl({value: '',disable: true}),
      'just_bem_recebido': new FormControl({value: '',disable: true}),
      'integracao_just': new FormControl({value: '',disable: true}),
      'materiais': new FormControl({value: '',disable: true}),
      'just_materiais': new FormControl({value: '',disable: true}),
      'estrutura': new FormControl({value: '',disable: true}),
      'just_estrutura': new FormControl({value: '',disable: true}),
      'treinamento': new FormControl({value: '',disable: true}),
      'just_treinamento': new FormControl({value: '',disable: true}),
      'funcao': new FormControl({value: '',disable: true}),
      'just_funcao': new FormControl({value: '',disable: true}),
// perguntas novas
      'uniforme': new FormControl({value: '',disable: true}),
      'just_uniforme': new FormControl({value: '',disable: true}),

      'ferramental': new FormControl({value: '',disable: true}),
      'just_ferramental': new FormControl({value: '',disable: true}),

      'material': new FormControl({value: '',disable: true}),
      'just_material': new FormControl({value: '',disable: true}),

      'equipamentos': new FormControl({value: '',disable: true}),
      'just_equipamentos': new FormControl({value: '',disable: true}),

      'atividades': new FormControl({value: '',disable: true}),
      'just_atividades': new FormControl({value: '',disable: true}),
      'metas': new FormControl({value: '',disable: true}),
      'just_metas': new FormControl({value: '',disable: true}),
      'orientava': new FormControl({value: '',disable: true}),
      'just_orientava': new FormControl({value: '',disable: true}),
      'sugestoes': new FormControl({value: '',disable: true}),
      'just_sugestoes': new FormControl({value: '',disable: true}),
      'tratamento': new FormControl({value: '',disable: true}),
      'just_tratamento': new FormControl({value: '',disable: true}),
      'medica': new FormControl({value: '',disable: true}),
      'coment_medica': new FormControl({value: '',disable: true}),
      'odonto': new FormControl({value: '',disable: true}),
      'coment_odonto': new FormControl({value: '',disable: true}),
      'ticket': new FormControl({value: '',disable: true}),
      'coment_ticket': new FormControl({value: '',disable: true}),
      'variavel': new FormControl({value: '',disable: true}),
      'coment_variavel': new FormControl({value: '',disable: true}),
      'recrutamento': new FormControl({value: '',disable: true}),
      'coment_recrutamento': new FormControl({value: '',disable: true}),
      'atendimento': new FormControl({value: '',disable: true}),
      'coment_atendimento': new FormControl({value: '',disable: true}),
      'atendRh': new FormControl({value: '',disable: true}),
      'coment_atendRh': new FormControl({value: '',disable: true}),

// perguntas novas
      'treinamentos': new FormControl({value: '',disable: true}),
      'coment_treinamentos': new FormControl({value: '',disable: true}),

      'treinamento_tecnico': new FormControl({value: '',disable: true}),
      'coment_treinamento_tecnico': new FormControl({value: '',disable: true}),

      'desenvolvimento_pdl': new FormControl({value: '',disable: true}),
      'coment_desenvolvimento_pdl': new FormControl({value: '',disable: true}),


      'horaextra': new FormControl({value: '',disable: true}),
      'coment_horaextra': new FormControl({value: '',disable: true}),
      'recomendar': new FormControl({value: '',disable: true}),
      'recomendar_just': new FormControl({value: '',disable: true}),
      'telefone_contato': new FormControl({value: '',disable: true}),
      'email_contato': new FormControl({value: '',disable: true})
    });
  }

  ngOnInit() {
    this._loadingService.show();
    this.preencherRespostas();
    this._loadingService.close();
  }

  preencherRespostas(){
    this.formEntrevista.get('matricula').setValue(this.entrevista.matricula);
    this.formEntrevista.get('nome').setValue(this.entrevista.nome);
    this.formEntrevista.get('dataAdmissao').setValue(this.entrevista.dataAdmissao.replace( /(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1").substring(0,10));
    this.formEntrevista.get('cargo').setValue(this.entrevista.cargo);
    this.formEntrevista.get('dataDemissao').setValue(this.entrevista.dataDemissao.replace( /(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1").substring(0,10));
    this.formEntrevista.get('gerente').setValue(this.entrevista.nomeGerente);
    this.formEntrevista.get('telefoneGerente').setValue(this.entrevista.telefoneGerente);
    this.formEntrevista.get('gestorImediato').setValue(this.entrevista.gestor);
    this.formEntrevista.get('telefoneGestor').setValue(this.entrevista.telefoneGestor);//.replace(//,""));
    this.formEntrevista.get('centroCusto').setValue(this.entrevista.centroCusto);
    this.formEntrevista.get('grupoHirarquico').setValue(this.entrevista.grupoHierarquico);
    this.formEntrevista.get('uf').setValue(this.entrevista.uf);
    this.formEntrevista.get('localAtuacao').setValue(this.entrevista.localAtuacao);
    this.formEntrevista.get('tempoEmpresa').setValue(this.entrevista.tempoEmpresa);
    this.formEntrevista.get('motivoDesligamento').setValue(this.entrevista.tipoDesligamento.descricao);

    this.finalForm = `${this.finalForm} ${this.entrevista.matricula} - ${this.entrevista.nome} em`;
    this.finalForm = `${this.finalForm} ${this.entrevista.dataDemissao.replace( /(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1").substring(0,10)} às ${this.entrevista.dataDemissao.substring(11,16)}`;

    // var resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 1);
    // if (resp) {
    //   this.formEntrevista.get('respondeu').setValue(resp.resposta.descricao);
    // }
    // else {
    //   this.formEntrevista.get('respondeu').setValue('');
    // }
    // resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 2);
    // if (resp) {
    //   this.formEntrevista.get('deficiencia').setValue(resp.resposta.descricao);
    //   this.formEntrevista.get('especifique').setValue(resp.descricao);
    // }
    // else {
    //   this.formEntrevista.get('deficiencia').setValue('');
    //   this.formEntrevista.get('especifique').setValue('');
    // }
   var resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 31);
    if (resp) {
      this.formEntrevista.get('obsMotivo').setValue(resp.resposta.descricao);
    }
    else {
      this.formEntrevista.get('obsMotivo').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 32);
    if (resp) {
      this.formEntrevista.get('novaEmpresa').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('novaEmpresa').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 33);
    if (resp) {
      this.formEntrevista.get('novoCargo').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('novoCargo').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 6);
    if (resp) {
      this.formEntrevista.get('processoSeletivo').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_processoSeletivo').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('processoSeletivo').setValue('');
      this.formEntrevista.get('just_processoSeletivo').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 7);
    if (resp) {
      this.formEntrevista.get('part_treinamento').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_part_treinamento').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('part_treinamento').setValue('');
      this.formEntrevista.get('just_part_treinamento').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 8);
    if (resp) {
      this.formEntrevista.get('bem_recebido').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_bem_recebido').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('bem_recebido').setValue('');
      this.formEntrevista.get('just_bem_recebido').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 10);
    if (resp) {
      this.formEntrevista.get('materiais').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_materiais').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('materiais').setValue('');
      this.formEntrevista.get('just_materiais').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 11);
    if (resp) {
      this.formEntrevista.get('estrutura').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_estrutura').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('estrutura').setValue('');
      this.formEntrevista.get('just_estrutura').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 12);
    if (resp) {
      this.formEntrevista.get('treinamento').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_treinamento').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('treinamento').setValue('');
      this.formEntrevista.get('just_treinamento').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 14);
    if (resp) {
      this.formEntrevista.get('funcao').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_funcao').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('funcao').setValue('');
      this.formEntrevista.get('just_funcao').setValue('');
    }

    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 40);
    if (resp) {
      this.formEntrevista.get('uniforme').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_uniforme').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('uniforme').setValue('');
      this.formEntrevista.get('just_uniforme').setValue('');
    }

    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 41);
    if (resp) {
      this.formEntrevista.get('ferramental').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_ferramental').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('ferramental').setValue('');
      this.formEntrevista.get('just_ferramental').setValue('');
    }

    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 42);
    if (resp) {
      this.formEntrevista.get('material').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_material').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('material').setValue('');
      this.formEntrevista.get('just_material').setValue('');
    }

    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 43);
    if (resp) {
      this.formEntrevista.get('equipamentos').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_equipamentos').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('equipamentos').setValue('');
      this.formEntrevista.get('just_equipamentos').setValue('');
    }

    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 15);
    if (resp) {
      this.formEntrevista.get('atividades').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_atividades').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('atividades').setValue('');
      this.formEntrevista.get('just_atividades').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 16);
    if (resp) {
      this.formEntrevista.get('metas').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_metas').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('metas').setValue('');
      this.formEntrevista.get('just_metas').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 18);
    if (resp) {
      this.formEntrevista.get('orientava').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_orientava').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('orientava').setValue('');
      this.formEntrevista.get('just_orientava').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 19);
    if (resp) {
      this.formEntrevista.get('sugestoes').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_sugestoes').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('sugestoes').setValue('');
      this.formEntrevista.get('just_sugestoes').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 20);
    if (resp) {
      this.formEntrevista.get('tratamento').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_tratamento').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('tratamento').setValue('');
      this.formEntrevista.get('just_tratamento').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 21);
    if (resp) {
      this.formEntrevista.get('medica').setValue(resp.resposta.descricao);
      this.formEntrevista.get('coment_medica').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('medica').setValue('');
      this.formEntrevista.get('coment_medica').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 22);
    if (resp) {
      this.formEntrevista.get('odonto').setValue(resp.resposta.descricao);
      this.formEntrevista.get('coment_odonto').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('odonto').setValue('');
      this.formEntrevista.get('coment_odonto').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 23);
    if (resp) {
      this.formEntrevista.get('ticket').setValue(resp.resposta.descricao);
      this.formEntrevista.get('coment_ticket').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('ticket').setValue('');
      this.formEntrevista.get('coment_ticket').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 24);
    if (resp) {
      this.formEntrevista.get('variavel').setValue(resp.resposta.descricao);
      this.formEntrevista.get('coment_variavel').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('variavel').setValue('');
      this.formEntrevista.get('coment_variavel').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 25);
    if (resp) {
      this.formEntrevista.get('recrutamento').setValue(resp.resposta.descricao);
      this.formEntrevista.get('coment_recrutamento').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('recrutamento').setValue('');
      this.formEntrevista.get('coment_recrutamento').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 26);
    if (resp) {
      this.formEntrevista.get('atendimento').setValue(resp.resposta.descricao);
      this.formEntrevista.get('coment_atendimento').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('atendimento').setValue('');
      this.formEntrevista.get('coment_atendimento').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 27);
    if (resp) {
      this.formEntrevista.get('atendRh').setValue(resp.resposta.descricao);
      this.formEntrevista.get('coment_atendRh').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('atendRh').setValue('');
      this.formEntrevista.get('coment_atendRh').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 37);
    if (resp) {
      this.formEntrevista.get('treinamentos').setValue(resp.resposta.descricao);
      this.formEntrevista.get('coment_treinamentos').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('treinamentos').setValue('');
      this.formEntrevista.get('coment_treinamentos').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 38);
    if (resp) {
      this.formEntrevista.get('treinamento_tecnico').setValue(resp.resposta.descricao);
      this.formEntrevista.get('coment_treinamento_tecnico').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('treinamento_tecnico').setValue('');
      this.formEntrevista.get('coment_treinamento_tecnico').setValue('');
    }

    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 39);
    if (resp) {
      this.formEntrevista.get('desenvolvimento_pdl').setValue(resp.resposta.descricao);
      this.formEntrevista.get('coment_desenvolvimento_pdl').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('desenvolvimento_pdl').setValue('');
      this.formEntrevista.get('coment_desenvolvimento_pdl').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 29);
    if (resp) {
      this.formEntrevista.get('horaextra').setValue(resp.resposta.descricao);
      this.formEntrevista.get('coment_horaextra').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('horaextra').setValue('');
      this.formEntrevista.get('coment_horaextra').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 57);
    if (resp) {
      this.formEntrevista.get('telefone_contato').setValue(resp.resposta.descricao);
    }
    else {
      this.formEntrevista.get('telefone_contato').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 58);
    if (resp) {
      this.formEntrevista.get('email_contato').setValue(resp.resposta.descricao);
    }
    else {
      this.formEntrevista.get('email_contato').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 35);
    if (resp) {
      this.formEntrevista.get('recomendar').setValue(resp.resposta.descricao);
      this.formEntrevista.get('recomendar_just').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('recomendar').setValue('');
      this.formEntrevista.get('recomendar_just').setValue('');
    }
  }

}
