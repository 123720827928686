import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExFuncionarioDomain } from 'src/app/domain/exfuncionario.domain';
import { ExFuncionarioService } from 'src/app/services/exfuncionario.service';
import { FormAbstract } from 'src/app/_core/form-abstract';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'form-aprendiz',
  templateUrl: './form-aprendiz.page.html',
  styleUrls: ['./form-aprendiz.page.sass']
})
export class FormAprendizPage extends FormAbstract<ExFuncionarioDomain, ExFuncionarioService> implements OnInit {

  @Input() entrevista: ExFuncionarioDomain;

  //public entrevista: ExFuncionarioDomain = new ExFuncionarioDomain();
  public formEntrevista: FormGroup;
  public entrevistas: ExFuncionarioDomain[] = [];

  public finalForm: string = "Entrevista respondida pelo colaborador"


  constructor( 
    private _entrevistasService: ExFuncionarioService,
    private _toastService: ToastrService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private _loadingService: LoadingService
  ) {
    super(_entrevistasService, activeRoute, router, _toastService)

    this.formEntrevista = new FormGroup({
      'matricula': new FormControl({value: '',disable: true}),
      'nome': new FormControl({value: '',disable: true}),
      'dataAdmissao': new FormControl({value: '',disable: true}),
      'cargo': new FormControl({value: '',disable: true}),
      'dataDemissao': new FormControl({value: '',disable: true}),
      'gerente': new FormControl({value: '',disable: true}),
      'telefoneGerente': new FormControl({value: '',disable: true}),
      'gestorImediato': new FormControl({value: '',disable: true}),
      'telefoneGestor': new FormControl({value: '',disable: true}),
      'centroCusto': new FormControl({value: '',disable: true}),
      'grupoHirarquico': new FormControl({value: '',disable: true}),
      'uf': new FormControl({value: '',disable: true}),
      'localAtuacao': new FormControl({value: '',disable: true}),
      'tempoEmpresa': new FormControl({value: '',disable: true}),
      'motivoDesligamento': new FormControl({value: '',disable: true}),
      'obsMotivo': new FormControl({value: '',disable: true}),
      'novaEmpresa': new FormControl({value: '',disable: true}),
      'novoCargo': new FormControl({value: '',disable: true}),
      'processoSeletivo': new FormControl({value: '',disable: true}),
      'just_processoSeletivo': new FormControl({value: '',disable: true}),
      'part_treinamento': new FormControl({value: '',disable: true}),
      'just_part_treinamento': new FormControl({value: '',disable: true}),
      'bem_recebido': new FormControl({value: '',disable: true}),
      'just_bem_recebido': new FormControl({value: '',disable: true}),
      'curso': new FormControl({value: '',disable: true}),
      'just_curso': new FormControl({value: '',disable: true}),
      'agregou': new FormControl({value: '',disable: true}),
      'just_agregou': new FormControl({value: '',disable: true}),
      'treinamento': new FormControl({value: '',disable: true}),
      'just_treinamento': new FormControl({value: '',disable: true}),
      'atividade': new FormControl({value: '',disable: true}),
      'just_atividade': new FormControl({value: '',disable: true}),
      'expectativas': new FormControl({value: '',disable: true}),
      'just_expectativas': new FormControl({value: '',disable: true}),
      'permanecer_area': new FormControl({value: '',disable: true}),
      'just_permanecer_area': new FormControl({value: '',disable: true}),
      'continuar_empresa': new FormControl({value: '',disable: true}),
      'just_continuar_empresa': new FormControl({value: '',disable: true}),
      'orientava': new FormControl({value: '',disable: true}),
      'just_orientava': new FormControl({value: '',disable: true}),
      'sugestoes': new FormControl({value: '',disable: true}),
      'just_sugestoes': new FormControl({value: '',disable: true}),
      'tratamento': new FormControl({value: '',disable: true}),
      'just_tratamento': new FormControl({value: '',disable: true}),
      'ticket': new FormControl({value: '',disable: true}),
      'coment_ticket': new FormControl({value: '',disable: true}),
      'remuneracao': new FormControl({value: '',disable: true}),
      'coment_remuneracao': new FormControl({value: '',disable: true}),
      'aval_curso': new FormControl({value: '',disable: true}),
      'coment_aval_curso': new FormControl({value: '',disable: true}),
      'atendRh': new FormControl({value: '',disable: true}),
      'coment_atendRh': new FormControl({value: '',disable: true}),
      'recomendar': new FormControl({value: '',disable: true}),
      'recomendar_just': new FormControl({value: '',disable: true})
    });
  }

  ngOnInit() {
    this._loadingService.show();
    this.preencherRespostas();
    this._loadingService.close();
  }

  preencherRespostas(){
    this.formEntrevista.get('matricula').setValue(this.entrevista.matricula);
    this.formEntrevista.get('nome').setValue(this.entrevista.nome);
    this.formEntrevista.get('dataAdmissao').setValue(this.entrevista.dataAdmissao.replace( /(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1").substring(0,10));
    this.formEntrevista.get('cargo').setValue(this.entrevista.cargo);
    this.formEntrevista.get('dataDemissao').setValue(this.entrevista.dataDemissao.replace( /(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1").substring(0,10));
    this.formEntrevista.get('gerente').setValue(this.entrevista.nomeGerente);
    this.formEntrevista.get('telefoneGerente').setValue(this.entrevista.telefoneGerente);
    this.formEntrevista.get('gestorImediato').setValue(this.entrevista.gestor);
    this.formEntrevista.get('telefoneGestor').setValue(this.entrevista.telefoneGestor);//.replace(//,""));
    this.formEntrevista.get('centroCusto').setValue(this.entrevista.centroCusto);
    this.formEntrevista.get('grupoHirarquico').setValue(this.entrevista.grupoHierarquico);
    this.formEntrevista.get('uf').setValue(this.entrevista.uf);
    this.formEntrevista.get('localAtuacao').setValue(this.entrevista.localAtuacao);
    this.formEntrevista.get('tempoEmpresa').setValue(this.entrevista.tempoEmpresa);
    this.formEntrevista.get('motivoDesligamento').setValue(this.entrevista.tipoDesligamento.descricao);

    this.finalForm = `${this.finalForm} ${this.entrevista.matricula} - ${this.entrevista.nome} em`;
    this.finalForm = `${this.finalForm} ${this.entrevista.dataDemissao.replace( /(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1").substring(0,10)} às ${this.entrevista.dataDemissao.substring(11,16)}`;

    var resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 31);
    if (resp) {
      this.formEntrevista.get('obsMotivo').setValue(resp.resposta.descricao);
    }
    else {
      this.formEntrevista.get('obsMotivo').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 32);
    if (resp) {
      this.formEntrevista.get('novaEmpresa').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('novaEmpresa').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 33);
    if (resp) {
      this.formEntrevista.get('novoCargo').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('novoCargo').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 6);
    if (resp) {
      this.formEntrevista.get('processoSeletivo').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_processoSeletivo').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('processoSeletivo').setValue('');
      this.formEntrevista.get('just_processoSeletivo').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 7);
    if (resp) {
      this.formEntrevista.get('part_treinamento').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_part_treinamento').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('part_treinamento').setValue('');
      this.formEntrevista.get('just_part_treinamento').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 8);
    if (resp) {
      this.formEntrevista.get('bem_recebido').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_bem_recebido').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('bem_recebido').setValue('');
      this.formEntrevista.get('just_bem_recebido').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 48);
    if (resp) {
      this.formEntrevista.get('curso').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_curso').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('curso').setValue('');
      this.formEntrevista.get('just_curso').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 49);
    if (resp) {
      this.formEntrevista.get('agregou').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_agregou').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('agregou').setValue('');
      this.formEntrevista.get('just_agregou').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 55);
    if (resp) {
      this.formEntrevista.get('treinamento').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_treinamento').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('treinamento').setValue('');
      this.formEntrevista.get('just_treinamento').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 56);
    if (resp) {
      this.formEntrevista.get('atividade').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_atividade').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('atividade').setValue('');
      this.formEntrevista.get('just_atividade').setValue('');
    }

    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 50);
    if (resp) {
      this.formEntrevista.get('expectativas').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_expectativas').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('expectativas').setValue('');
      this.formEntrevista.get('just_expectativas').setValue('');
    }

    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 51);
    if (resp) {
      this.formEntrevista.get('permanecer_area').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_permanecer_area').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('permanecer_area').setValue('');
      this.formEntrevista.get('just_permanecer_area').setValue('');
    }

    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 52);
    if (resp) {
      this.formEntrevista.get('continuar_empresa').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_continuar_empresa').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('continuar_empresa').setValue('');
      this.formEntrevista.get('just_continuar_empresa').setValue('');
    }

    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 18);
    if (resp) {
      this.formEntrevista.get('orientava').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_orientava').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('orientava').setValue('');
      this.formEntrevista.get('just_orientava').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 19);
    if (resp) {
      this.formEntrevista.get('sugestoes').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_sugestoes').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('sugestoes').setValue('');
      this.formEntrevista.get('just_sugestoes').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 20);
    if (resp) {
      this.formEntrevista.get('tratamento').setValue(resp.resposta.descricao);
      this.formEntrevista.get('just_tratamento').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('tratamento').setValue('');
      this.formEntrevista.get('just_tratamento').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 23);
    if (resp) {
      this.formEntrevista.get('ticket').setValue(resp.resposta.descricao);
      this.formEntrevista.get('coment_ticket').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('ticket').setValue('');
      this.formEntrevista.get('coment_ticket').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 53);
    if (resp) {
      this.formEntrevista.get('remuneracao').setValue(resp.resposta.descricao);
      this.formEntrevista.get('coment_remuneracao').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('remuneracao').setValue('');
      this.formEntrevista.get('coment_remuneracao').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 54);
    if (resp) {
      this.formEntrevista.get('aval_curso').setValue(resp.resposta.descricao);
      this.formEntrevista.get('coment_aval_curso').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('aval_curso').setValue('');
      this.formEntrevista.get('coment_aval_curso').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 27);
    if (resp) {
      this.formEntrevista.get('atendRh').setValue(resp.resposta.descricao);
      this.formEntrevista.get('coment_atendRh').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('atendRh').setValue('');
      this.formEntrevista.get('coment_atendRh').setValue('');
    }
    resp = this.entrevista.respostaPerguntas.find(p => p.idPergunta == 35);
    if (resp) {
      this.formEntrevista.get('recomendar').setValue(resp.resposta.descricao);
      this.formEntrevista.get('recomendar_just').setValue(resp.descricao);
    }
    else {
      this.formEntrevista.get('recomendar').setValue('');
      this.formEntrevista.get('recomendar_just').setValue('');
    }
  }

}
