import { environment } from '../../environments/environment';
import { JsonProperty, ObjectMapper, JsonIgnore } from 'json-object-mapper';
import { DomainBase } from "./base/base.domain";

export class RespostaDomain extends DomainBase {

    @JsonProperty({name: 'descricao_resposta'})
    public descricao: string = '';

    @JsonProperty({name: 'ativo'})
    public ativo: boolean = false;

    @JsonProperty({name: 'id_pergunta'})
    public pergunta: number = 0;

    public static map(object: any): RespostaDomain {
        let obj = ObjectMapper.deserialize(this, object);
        return obj;
    }
}