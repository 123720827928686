import { JsonProperty } from 'json-object-mapper';
import { SelectorBase } from './base/base.selector';

export class DivergenciaSelector extends SelectorBase {

    @JsonProperty({name: 'importacao_id'})
    public importacaoId: number = 0;

    @JsonProperty({name: 'matricula_colaborador'})
    public matriculaColaborador: string = '';

    @JsonProperty({name: 'nome_colaborador'})
    public nomeColaborador: string = '';

    @JsonProperty({name: 'descricao_divergencia'})
    public descricaoDivergencia: string = '';
    
}