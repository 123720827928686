import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
//import { LoadingModule } from "ngx-loading";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UserService } from "../../services/user.service";
import { HomePage } from "./home.page";
import { BsDatepickerModule } from "ngx-bootstrap";
import { AppRoutingModule } from 'src/app/app-routing.module';


@NgModule({
    declarations: [ 
        HomePage
    ],
    imports: [ 
        CommonModule, 
        AppRoutingModule,
        //LoadingModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule.forRoot(),
    ],
    exports: [ 
        HomePage
    ],
    providers: [
        UserService
    ],
})
export class HomeModule {}