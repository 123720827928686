import { Component, OnInit, Input, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient as CoreHttp } from '../../_core/http.cliente';
import { HttpClient, HttpEventType, HttpHeaders} from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/domain/user.domain';

@Injectable()
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.sass']
})

export class UploadComponent implements OnInit {

  user: User;
  matricula: string;
  solicitante: string;

  ngOnInit() {

  }

  // alerts: Alert[];

  @Input()
  endPoint: string;

  fileData: File = null;
  jsonData : string;

  uploading = false;
  files = false;
  response : any;

  progress = 0;

  success: boolean = false;
  error: boolean = false;
  errorMsg: String;

  // close(alert: Alert) {
  //   this.alerts.splice(this.alerts.indexOf(alert), 1);
  // }

  constructor(private CommonHttp: HttpClient, private http:CoreHttp, private _user: UserService ) { }

  fileChange(event) {
      this.fileData = event.target.files[0];
      this.files = true;
  }

  async onSubmit() {
    this.uploading = true;

    // await this.http.get('colaborador/getusuariologado').subscribe(col => {
    //   let res = col.data;

    this._user.getUser().then( user => {
      this.user = user;
      this.solicitante = this.user.fullName;
      this.matricula = this.user.username;

      const formData = new FormData();
      formData.append('file', this.fileData);

      formData.append('id_serede', this.user.username);

      let headers = new HttpHeaders({
        'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
      });

      this.CommonHttp.post(`${environment.ApiUrl}/${this.endPoint}`, formData, {
        headers
      }).toPromise()
      .then(response => {
        console.log(response)
        this.success = true;
      })
      .catch(err => {
        let _err: any = err;
        console.log(err)
        this.error = true;
        this.errorMsg = _err.error.error_message
      });
    });
  }
}
