import { Injectable } from '@angular/core';
import { HttpClient } from "../_core/http.cliente";
import { ServiceBase } from './base/base.service';
import { ExFuncionarioDomain } from '../domain/exfuncionario.domain';
import { ExFuncionarioSelector } from '../selectors/exfuncionario.selector';
import { Observable } from 'rxjs';
import { Response } from '../domain/response.domain';


@Injectable()
export class ExFuncionarioService extends ServiceBase<ExFuncionarioSelector, ExFuncionarioDomain> {

  constructor(http: HttpClient) {
    super('exfuncionario', http);
  }


  habilitar(object: ExFuncionarioSelector): Observable<Response<ExFuncionarioDomain>>{
    return this.Http.post(this.EndPoint + '/habilitar', object.toJSON());
  }

  desabilitar(object: ExFuncionarioSelector): Observable<Response<ExFuncionarioDomain>>{
    return this.Http.post(this.EndPoint + '/desabilitar', object.toJSON());
  }


  solicitar(selector: ExFuncionarioSelector): Observable<any>{    
    return this.Http.post(this.EndPoint + '/solicitar', selector.toJSON());    
  }

  autorizarManual(object: ExFuncionarioSelector): Observable<Response<ExFuncionarioDomain>>{
    return this.Http.post(this.EndPoint + '/autorizarManual', object.toJSON());
  }

  tratarManual(object: ExFuncionarioSelector): Observable<Response<ExFuncionarioDomain>>{
    return this.Http.post(this.EndPoint + '/salvarTratativa', object.toJSON());
  }

  getTotal(object: ExFuncionarioSelector): Observable<any>{
    return this.Http.post(this.EndPoint + '/count', object.toJSON());
  }

  carregarCentrosCusto(object: ExFuncionarioSelector): Observable<any>{
    return this.Http.post(this.EndPoint + '/listCentrosCusto', object.toJSON());
  }

  carregarStatus(): Observable<any>{
    return this.Http.get(this.EndPoint + '/listStatus');
  }

  createEntity(input: any): ExFuncionarioDomain {    
    return ExFuncionarioDomain.map(input);
    
  } 
}
