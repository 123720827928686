import { Component, OnInit, TemplateRef, OnDestroy } from "@angular/core";
import { ImportacaoService } from "src/app/services/importacao.service";
import { ImportacaoSelector } from "src/app/selectors/importacao.selector";
import { ImportacaoDomain } from "src/app/domain/importacao.domain";
import { ListAbstract } from "src/app/services/base/list.service";
import { User } from "src/app/domain/user.domain";
import { UserService } from "src/app/services/user.service";
import { LoadingService } from "src/app/services/loading.service";
import {
  BsModalService,
  BsLocaleService,
  ptBrLocale,
  defineLocale
} from "ngx-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DivergenciaDomain } from "src/app/domain/divergencia.domain";
import { DivergenciaService } from "src/app/services/divergencia.service";
import { DivergenciaSelector } from "src/app/selectors/divergencia.selector";
import { ToastrService } from "../../../../../node_modules/ngx-toastr";
import { Angular5Csv } from "../../../../../node_modules/angular5-csv/dist/Angular5-csv";

@Component({
  selector: "app-list",
  templateUrl: "./importacao-list.page.html",
  styleUrls: ["./importacao-list.page.sass"]
})
export class ImportacaoListPage
  extends ListAbstract<ImportacaoDomain, ImportacaoSelector, ImportacaoService>
  implements OnInit, OnDestroy {
  modalRef: any;
  [x: string]: any;

  pagina: number = 1;
  paginamodal: number = 1;

  bsConfig: { containerClass: string };
  user: User;
  arquivo: string;
  nomeArquivo: string;
  matricula: string;
  solicitante: string;
  dataImportacao: string;
  public divergenciaSelector: DivergenciaSelector = new DivergenciaSelector();
  closeCollapse: boolean;
  origin: string;

  interval: any;
  public formTratamentoGroup: FormGroup;
  public divergencia: DivergenciaDomain = new DivergenciaDomain();
  public divergenciaList: DivergenciaDomain[] = [];

  constructor(
    private _importacaoService: ImportacaoService,
    private _user: UserService,
    private _toastService: ToastrService,
    private _loadingService: LoadingService,
    private _divergenciaService: DivergenciaService,
    private _localeService: BsLocaleService,
    private _modalService: BsModalService
  ) {
    super(_importacaoService, new ImportacaoSelector());
    this.loading = _loadingService;
    this.toast = _toastService;
  }

  ngOnInit() {
    this.bsConfig = Object.assign({}, { containerClass: "theme-orange" });
    defineLocale("pt-br", ptBrLocale);
    this._localeService.use("pt-br");

    this._importacaoService
      .list(new ImportacaoSelector())
      .subscribe(async x => {
        let arr = this.Seletor.removeDuplicate(await x.Data.map(x => x.status));
        this.Seletor.statuslist = arr;
        //this.Seletor.datelist = x.Data.map(x => x.dataImportacao);
        this._loadingService.close();
      });

    this.definirIntervalCarregamento();
    //  this._user.getUser().then( user => {
    //   this.user = user;
    //   this.solicitante = this.user.fullName;
    //   this.matricula = this.user.username;
    // });

    this.filter();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  filter() {
    this.query();
  }

  definirIntervalCarregamento() {
    this.interval = setInterval(() => {
      this.query();
    }, 15000);
  }

  csv(importacao: ImportacaoDomain) {
    var data = [];
    var options = {
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      // showTitle: true,
      useBom: true,
      headers: [
        // "UF",
        // "EMPRESA",
        // "CENTRO DE CUSTO",
        // "DIR.",
        // "GERENCIA",
        "ID A SER PROMOVIDO/DESLIGADO"
        // "CPF",
        // "COLABORADOR A SER PROMOVIDO/DESLIGADO",
        // "COD. CÁRGO",
        // "CARGO ATUAL DO COLABORADOR A SER PROMOVIDO/ DESLIGADO",
        // "GESTOR"
      ]
    };
    this.Seletor.id = importacao.id;
    this._importacaoService.download(this.Seletor).subscribe(x => {
      if (x.json().data != "error") {
        if (x.json().data.length > 0) {
          var arquivoCsv = x.json().data;
          new Angular5Csv(x.json().data, "PlanilhaImportada", options);
          this._toastService.success("Sucesso", "Arquivo emitido com sucesso!");
        } else {
          this._toastService.error("Atenção", "Nenhum registro selecionado!");
        }
      } else {
        this._toastService.error(
          "Error",
          "Ops ocorreu um error ao tentar baixar o arquivo!"
        );
      }
    });
  }

  openModalDivergencia(
    importacao: ImportacaoDomain,
    template: TemplateRef<any> = null
  ) {
    this._loadingService.show();
    this.divergenciaList = null;
    this.divergenciaSelector.importacaoId = importacao.id;
    this.solicitante = importacao.solicitante;
    this.matricula = importacao.matriculaSolicitante;
    this.nomeArquivo = importacao.nomeArquivo;
    this.dataImportacao = importacao.dataImportacao
      .replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1")
      .substring(0, 10);
    this._divergenciaService.list(this.divergenciaSelector).subscribe(x => {
      this.divergenciaList = x.Data.map(x => x);
      if (template != null) {
        this.modalRef = this._modalService.show(template, {
          class: "modal-lg"
        });
      }
      this._loadingService.close();
    });
  }
}
