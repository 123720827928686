import { Injectable } from '@angular/core';
import { TipoTratativaDomain } from '../domain/tipotratativa.domain';
import { TipoTratativaSelector } from '../selectors/tipotratativa.selector';
import { HttpClient } from '../_core/http.cliente';
import { ServiceBase } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class TipoTratativaService extends ServiceBase<TipoTratativaSelector, TipoTratativaDomain> {

  constructor(http: HttpClient) {
    super('TipoTratativa', http);
  }

  createEntity(input: any): TipoTratativaDomain {      
    return TipoTratativaDomain.map(input);
  }
}
