import { JsonProperty, JsonIgnore, ObjectMapper } from 'json-object-mapper';
import { SelectorBase } from './base/base.selector';
import { TipoDesligamentoDomain } from '../domain/tipodeligamento.domain';
import { parseTwoDigitYear } from 'ngx-bootstrap/chronos/units/year';

export class RelatorioSelector extends SelectorBase {
    @JsonIgnore()
    public today: Date = new Date();

    @JsonIgnore() //6 meses antes da data atual
    public last: Date = new Date(`${this.today.getMonth()-5}/${this.today.getDate()}/${this.today.getFullYear()}`);
    
    @JsonProperty({name: 'tipo_desligamento_id'})
    public tipoDesligamentoId: number = 0;

    @JsonProperty({name: 'dtinicio'})
    public dtinicio: string = '';
    
    @JsonProperty({name: 'dtfim'})
    public dtfim: string = '';

    @JsonProperty({name: 'tipo_relatorio'})
    public tipoRelatorio: number = 0;

    @JsonIgnore()
    public motivosList: TipoDesligamentoDomain[];

    @JsonIgnore()
    public datelist: Date[] = [this.last, this.today];
    private _readDates(){
        
        if (this.datelist.length) {
            this.dtinicio = `${this.datelist[0].getMonth() + 1}/${this.datelist[0].getDate()}/${this.datelist[0].getFullYear()}`;
            this.dtfim = `${this.datelist[1].getMonth() + 1}/${this.datelist[1].getDate()}/${this.datelist[1].getFullYear()}`;
        } else {
            this.dtinicio =  '';
            this.dtfim =  '';
        }
    }

    public toJSON(): string {
        
        this._readDates();
        return <string>ObjectMapper.serialize(this);
    }
}