import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from "../../app-routing.module";
import { RelatoriosPage } from './relatorios.page';
import { RelatoriosListPage } from './list/relatorios-list.page';
import { RelatorioService } from 'src/app/services/relatorio.service';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { TipoDesligamentoService } from 'src/app/services/tipodesligamento.service';


@NgModule({
  declarations: [
    RelatoriosPage,
    RelatoriosListPage
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
    RelatoriosPage
  ],
  providers: [
    RelatorioService,
    TipoDesligamentoService              
  ]
})
export class RelatoriosModule { }
