import { environment } from '../../environments/environment';
import { JsonProperty, ObjectMapper, JsonIgnore } from 'json-object-mapper';
import { DomainBase } from "./base/base.domain";

export class DivergenciaDomain extends DomainBase {

    @JsonProperty({name: 'importacao_id'})
    public importacaoId: number = 0;

    @JsonProperty({name: 'matricula_colaborador'})
    public matriculaColaborador: string = '';

    @JsonProperty({name: 'nome_colaborador'})
    public nomeColaborador: string = '';

    @JsonProperty({name: 'descricao_divergencia'})
    public descricaoDivergencia: string = '';

    public static map(object: any): DivergenciaDomain {
        let obj = ObjectMapper.deserialize(this, object);
        return obj;
    }
}