export class JwtHelper {
    // decode token
    static decodeToken(token:string): any {
        var parts = token.split('.');
        if(parts.length !== 3){
          throw new Error('JWT must have 3 parts');
        }
  
        var decoded = atob(parts[1]);
        if(!decoded){
          throw new Error('Cannot decode the token');
        }
  
        return JSON.parse(decoded);
    }
    
    static getTokenExpirationDate(token:string) {
        var decoded: any;
        decoded = this.decodeToken(token);

        if(typeof decoded.exp === "undefined") {
            return null;
        }

        var date = new Date(0); // The 0 here is the key, which sets the date to the epoch
        date.setUTCSeconds(decoded.exp);

        return date;
    }
    
    static isTokenExpired(token:string, offsetSeconds?:number) {
        var date = this.getTokenExpirationDate(token);
        offsetSeconds = offsetSeconds || 0;
        if (date === null) {
            return false;
        }

        // Token expired?
        return !(date.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)));
    }
}