import { SelectorBase } from "../../selectors/base/base.selector";
import { DomainBase } from "../../domain/base/base.domain";
import { HttpClient } from '../../_core/http.cliente';
import { Observable } from 'rxjs';
import { Response } from "../../domain/response.domain";
import { map } from 'rxjs/operators';

export abstract class ServiceBase<S extends SelectorBase, D extends DomainBase> {

    constructor(
        private _endpoint: string, 
        private _http: HttpClient
    ) { }

    abstract createEntity(input: any): D;

    get Http(): HttpClient {
        return this._http;
    }

    get EndPoint(): string {
        return this._endpoint;
    }

    get(id: number): Observable<Response<D>> {
        return this._http.get(this._endpoint + '/' + id)
        .pipe(
            map(res => {
                let response = new Response<D>();
                response.Data = this.createEntity(res.data) as D;
                return response;
            })          
        );

    }

    list(seletor: S): Observable<Response<D[]>> {
        return this._http.post(this._endpoint + '/list', seletor.toJSON())
        .pipe(
            map(res => {
                let response = new Response<D[]>();
                response.Data = res.json().data.map(pp => this.createEntity(pp) as D);
                // response.TotalRows = +res.headers.get('x-total-rows');
                
                response.TotalRows = res.json().TotalRows;
                return response;
            })          
        );
    }

    save(id: number, object: D): Observable<Response<D>> {
        
        let observer = (id => {
            if (!id) {
                return this._http.post(this._endpoint, object.toJSON());
            } else {
                return this._http.put(this._endpoint + '/' + id, object.toJSON());
            }
        });

         return observer(id)
         .pipe(
             map(res => {
                 let response = new Response<D>();
                 response.Data = this.createEntity(res.json()) as D;
                 return response;
             })            
        );
    }

    enableDisable(id: number): Observable<any> {
        return this.Http.put(this.EndPoint + `/status/${id}`, null);
    }

    delete(id: number): Observable<any> {
        return this.Http.delete(this.EndPoint + `/${id}`);
    }

    Cancelar(id: number, matricula: string): Observable<any> {
        return this.Http.delete(this.EndPoint + `/${id}` + `/${matricula}`);
    }
}