import { environment } from "../../environments/environment";
import { JsonProperty, ObjectMapper, JsonIgnore } from "json-object-mapper";
import { DomainBase } from "./base/base.domain";
import { ExFuncionarioDomain } from "./exfuncionario.domain";

export class RelatorioDomain extends DomainBase {
  @JsonProperty({ name: "id_colaborador" })
  public id_colaborador: string = "";

  @JsonProperty({ name: "nm_colaborador" })
  public nm_colaborador: string = "";

  @JsonProperty({ name: "ds_cargo" })
  public ds_cargo: string = "";

  @JsonProperty({ name: "gestor_imediato" })
  public gestor_imediato: string = "";

  @JsonProperty({ name: "ds_gerencia" })
  public ds_gerencia: string = "";

  @JsonProperty({ name: "telefone" })
  public telefone: string = "";

  @JsonProperty({ name: "ds_sigla" })
  public ds_sigla: string = "";

  @JsonProperty({ name: "dh_admissao" })
  public dh_admissao: string = "";

  @JsonProperty({ name: "dh_demissao" })
  public dh_demissao: string = "";

  @JsonProperty({ name: "dh_resposta" })
  public dh_resposta: string = "";

  @JsonProperty({ name: "E_PORTADOR_DE_ALGUMA_DEFICIENCIA" })
  public E_PORTADOR_DE_ALGUMA_DEFICIENCIA: string = "";

  @JsonProperty({ name: "CASO_SIM_ESPECIFIQUE" })
  public CASO_SIM_ESPECIFIQUE: string = "";

  @JsonProperty({ name: "RESPONDERA_PESQUISA" })
  public RESPONDERA_PESQUISA: string = "";

  @JsonProperty({ name: "TEMPO_DE_EMPRESA" })
  public TEMPO_DE_EMPRESA: string = "";

  @JsonProperty({ name: "PEDIDO_DE_DESLIGAMENTO" })
  public PEDIDO_DE_DESLIGAMENTO: string = "";

  @JsonProperty({ name: "PARA_QUE_EMPRESA_ESTA_INDO" })
  public PARA_QUE_EMPRESA_ESTA_INDO: string = "";

  @JsonProperty({ name: "QUAL_CARGO" })
  public QUAL_CARGO: string = "";

  @JsonProperty({
    name: "A_NOVA_OPORTUNIDADE_SE_COMPARADA_COM_A_NOSSA_PODE_SER_CONSIDERADA"
  })
  public A_NOVA_OPORTUNIDADE_SE_COMPARADA_COM_A_NOSSA_PODE_SER_CONSIDERADA: string =
    "";

  @JsonProperty({ name: "DESLIGADO" })
  public DESLIGADO: string = "";

  @JsonProperty({
    name:
      "O_PROCESSO_SELETIVO_FOI_CLARO_QUANTO_AS_QUESTOES_QUE_ENVOLVIAM_SALARIO_FUNCAO_EQUIPE_LIDERANÇA_EMPRESA_E_CARGA_HORARIA"
  })
  public O_PROCESSO_SELETIVO_FOI_CLARO_QUANTO_AS_QUESTOES_QUE_ENVOLVIAM_SALARIO_FUNCAO_EQUIPE_LIDERANÇA_EMPRESA_E_CARGA_HORARIA: string =
    "";

  @JsonProperty({
    name: "VOCE_PARTICIPOU_DO_TREINAMENTO_DE_INTEGRACAO_AO_ENTRAR_NA_EMPRESA"
  })
  public VOCE_PARTICIPOU_DO_TREINAMENTO_DE_INTEGRACAO_AO_ENTRAR_NA_EMPRESA: string =
    "";

  @JsonProperty({ name: "VOCE_FOI_BEM_RECEBIDO_NO_SEU_SETOR_DE_TRABALHO" })
  public VOCE_FOI_BEM_RECEBIDO_NO_SEU_SETOR_DE_TRABALHO: string = "";

  @JsonProperty({
    name: "VOCE_FOI_BEM_RECEBIDO_NO_SEU_SETOR_DE_TRABALHO_JUSTIFIQUE"
  })
  public VOCE_FOI_BEM_RECEBIDO_NO_SEU_SETOR_DE_TRABALHO_JUSTIFIQUE: string = "";

  @JsonProperty({
    name: "VOCE_TINHA_RECURSOS_MATERIAIS_PARA_REALIZAR_SEU_TRABALHO"
  })
  public VOCE_TINHA_RECURSOS_MATERIAIS_PARA_REALIZAR_SEU_TRABALHO: string = "";

  @JsonProperty({
    name: "VOCE_TINHA_RECURSOS_MATERIAIS_PARA_REALIZAR_SEU_TRABALHO_JUSTIFIQUE"
  })
  public VOCE_TINHA_RECURSOS_MATERIAIS_PARA_REALIZAR_SEU_TRABALHO_JUSTIFIQUE: string =
    "";

  @JsonProperty({
    name:
      "A_ESTRUTURA_FISICA_DA_EMPRESA_ERA_ADEQUADA_PARA_TRABALHO_QUE_VOCE_REALIZAVA"
  })
  public A_ESTRUTURA_FISICA_DA_EMPRESA_ERA_ADEQUADA_PARA_TRABALHO_QUE_VOCE_REALIZAVA: string =
    "";

  @JsonProperty({
    name:
      "A_ESTRUTURA_FISICA_DA_EMPRESA_ERA_ADEQUADA_PARA_TRABALHO_QUE_VOCE_REALIZAVA_JUSTIFIQUE"
  })
  public A_ESTRUTURA_FISICA_DA_EMPRESA_ERA_ADEQUADA_PARA_TRABALHO_QUE_VOCE_REALIZAVA_JUSTIFIQUE: string =
    "";

  @JsonProperty({
    name:
      "A_EMPRESA_OFERECEU_TREINAMENTO_ADEQUADO_NECESSARIO_PARA_SEU_DESENVOLVIMENTO_PROFISSIONAL"
  })
  public A_EMPRESA_OFERECEU_TREINAMENTO_ADEQUADO_NECESSARIO_PARA_SEU_DESENVOLVIMENTO_PROFISSIONAL: string =
    "";

  @JsonProperty({
    name:
      "A_EMPRESA_OFERECEU_TREINAMENTO_ADEQUADO_NECESSARIO_PARA_SEU_DESENVOLVIMENTO_PROFISSIONAL_JUSTIFIQUE"
  })
  public A_EMPRESA_OFERECEU_TREINAMENTO_ADEQUADO_NECESSARIO_PARA_SEU_DESENVOLVIMENTO_PROFISSIONAL_JUSTIFIQUE: string =
    "";

  @JsonProperty({ name: "VOCE_SE_IDENTIFICAVA_COM_FUNÇAO_QUE_EXERCIA" })
  public VOCE_SE_IDENTIFICAVA_COM_FUNÇAO_QUE_EXERCIA: string = "";

  @JsonProperty({
    name: "VOCE_SE_IDENTIFICAVA_COM_FUNÇAO_QUE_EXERCIA_JUSTIFIQUE"
  })
  public VOCE_SE_IDENTIFICAVA_COM_FUNÇAO_QUE_EXERCIA_JUSTIFIQUE: string = "";

  @JsonProperty({
    name: "AS_ATIVIDADES_QUE_VOCE_REALIZAVA_ESTAVAM_DE_ACORDO_COM_FUNÇAO"
  })
  public AS_ATIVIDADES_QUE_VOCE_REALIZAVA_ESTAVAM_DE_ACORDO_COM_FUNÇAO: string =
    "";

  @JsonProperty({
    name:
      "AS_ATIVIDADES_QUE_VOCE_REALIZAVA_ESTAVAM_DE_ACORDO_COM_FUNÇAO_JUSTIFIQUE"
  })
  public AS_ATIVIDADES_QUE_VOCE_REALIZAVA_ESTAVAM_DE_ACORDO_COM_FUNÇAO_JUSTIFIQUE: string =
    "";

  @JsonProperty({ name: "VOCE_CONHECIA_AS_METAS_REFERENTES_SUA_AREA" })
  public VOCE_CONHECIA_AS_METAS_REFERENTES_SUA_AREA: string = "";

  @JsonProperty({
    name: "VOCE_CONHECIA_AS_METAS_REFERENTES_SUA_AREA_JUSTIFIQUE"
  })
  public VOCE_CONHECIA_AS_METAS_REFERENTES_SUA_AREA_JUSTIFIQUE: string = "";

  @JsonProperty({
    name: "SEU_GESTOR_ORIENTAVA_ACOMPANHAVA_REALIZAÇAO_DE_SUAS_TAREFAS"
  })
  public SEU_GESTOR_ORIENTAVA_ACOMPANHAVA_REALIZAÇAO_DE_SUAS_TAREFAS: string =
    "";

  @JsonProperty({
    name:
      "SEU_GESTOR_ORIENTAVA_ACOMPANHAVA_REALIZAÇAO_DE_SUAS_TAREFAS_JUSTIFIQUE"
  })
  public SEU_GESTOR_ORIENTAVA_ACOMPANHAVA_REALIZAÇAO_DE_SUAS_TAREFAS_JUSTIFIQUE: string =
    "";

  @JsonProperty({
    name: "SEU_GESTOR_OUVIA_LEVAVA_CONSIDERAÇAO_SUAS_IDEIAS_SUGESTOES"
  })
  public SEU_GESTOR_OUVIA_LEVAVA_CONSIDERAÇAO_SUAS_IDEIAS_SUGESTOES: string =
    "";

  @JsonProperty({
    name:
      "SEU_GESTOR_OUVIA_LEVAVA_CONSIDERAÇAO_SUAS_IDEIAS_SUGESTOES_JUSTIFIQUE"
  })
  public SEU_GESTOR_OUVIA_LEVAVA_CONSIDERAÇAO_SUAS_IDEIAS_SUGESTOES_JUSTIFIQUE: string =
    "";

  @JsonProperty({
    name: "SEU_GESTOR_TRATAVA_VOCE_FORMA_RESPEITOSA_PROFISSIONAL"
  })
  public SEU_GESTOR_TRATAVA_VOCE_FORMA_RESPEITOSA_PROFISSIONAL: string = "";

  @JsonProperty({
    name: "SEU_GESTOR_TRATAVA_VOCE_FORMA_RESPEITOSA_PROFISSIONAL_JUSTIFIQUE"
  })
  public SEU_GESTOR_TRATAVA_VOCE_FORMA_RESPEITOSA_PROFISSIONAL_JUSTIFIQUE: string =
    "";

  @JsonProperty({ name: "ASSISTENCIA_MEDICA" })
  public ASSISTENCIA_MEDICA: string = "";

  @JsonProperty({ name: "ASSISTENCIA_ODONTOLOGICA" })
  public ASSISTENCIA_ODONTOLOGICA: string = "";

  @JsonProperty({ name: "TICKET_REFEIÇAO_ALIMENTAÇAO" })
  public TICKET_REFEIÇAO_ALIMENTAÇAO: string = "";

  @JsonProperty({ name: "REMUNERAÇAO_VARIAVEL" })
  public REMUNERAÇAO_VARIAVEL: string = "";

  @JsonProperty({ name: "JUST_REMUNERAÇAO_VARIAVEL" })
  public JUST_REMUNERAÇAO_VARIAVEL: string = "";

  @JsonProperty({ name: "PROGRAMA_RECRUTAMENTO_INTERNO" })
  public PROGRAMA_RECRUTAMENTO_INTERNO: string = "";

  @JsonProperty({ name: "ATENDIMENTO_ATENDE_SEREDE_CONECTA" })
  public ATENDIMENTO_ATENDE_SEREDE_CONECTA: string = "";

  @JsonProperty({ name: "ATENDIMENTO_RH_LOCAL" })
  public ATENDIMENTO_RH_LOCAL: string = "";

  @JsonProperty({ name: "TREINAMENTOS" })
  public TREINAMENTOS: string = "";

  @JsonProperty({
    name:
      "VOCE_RECOMENDARIA_EMPRESA_PARA_OUTRO_PROFISSIONAL_COMO_UMA_BOA_EMPRESA_PARA_SE_TRABALHAR"
  })
  public VOCE_RECOMENDARIA_EMPRESA_PARA_OUTRO_PROFISSIONAL_COMO_UMA_BOA_EMPRESA_PARA_SE_TRABALHAR: string =
    "";

  @JsonProperty({
    name:
      "VOCE_RECOMENDARIA_EMPRESA_PARA_OUTRO_PROFISSIONAL_COMO_UMA_BOA_EMPRESA_PARA_SE_TRABALHAR_JUSTIQUE"
  })
  public VOCE_RECOMENDARIA_EMPRESA_PARA_OUTRO_PROFISSIONAL_COMO_UMA_BOA_EMPRESA_PARA_SE_TRABALHAR_JUSTIQUE: string =
    "";

  @JsonProperty({ name: "HORA_EXTRA" })
  public HORA_EXTRA: string = "";

  @JsonProperty({ name: "HORA_EXTRA_JUSTIFIQUE" })
  public HORA_EXTRA_JUSTIFIQUE: string = "";

  @JsonProperty({ name: "TREINAMENTO_LEGAL_NRS" })
  public TREINAMENTO_LEGAL_NRS: string = "";

  @JsonProperty({ name: "TREINAMENTO_LEGAL_NRS_JUSTIFIQUE" })
  public TREINAMENTO_LEGAL_NRS_JUSTIFIQUE: string = "";

  @JsonProperty({ name: "TREINAMENTO_TECNICO " })
  public TREINAMENTO_TECNICO: string = "";

  @JsonProperty({ name: "TREINAMENTO_TECNICO_JUSTIFIQUE" })
  public TREINAMENTO_TECNICO_JUSTIFIQUE: string = "";

  @JsonProperty({ name: "DESENVOLVIMENTO_RESULTADOS_GESTAO_ROTINA" })
  public DESENVOLVIMENTO_RESULTADOS_GESTAO_ROTINA: string = "";

  @JsonProperty({ name: "DESENVOLVIMENTO_RESULTADOS_GESTAO_ROTINA_JUSTIFIQUE" })
  public DESENVOLVIMENTO_RESULTADOS_GESTAO_ROTINA_JUSTIFIQUE: string = "";

  @JsonProperty({ name: "EPI_EPC_UNIFORME" })
  public EPI_EPC_UNIFORME: string = "";

  @JsonProperty({ name: "EPI_EPC_UNIFORME_JUSTIFIQUE" })
  public EPI_EPC_UNIFORME_JUSTIFIQUE: string = "";

  @JsonProperty({ name: "FERRAMENTAL" })
  public FERRAMENTAL: string = "";

  @JsonProperty({ name: "FERRAMENTAL_JUSTIFIQUE" })
  public FERRAMENTAL_JUSTIFIQUE: string = "";

  @JsonProperty({ name: "MATERIAL_JUSTIFIQUE" })
  public MATERIAL_JUSTIFIQUE: string = "";

  @JsonProperty({ name: "MATERIAL" })
  public MATERIAL: string = "";

  @JsonProperty({ name: "CELULAR_NOTEBOOK" })
  public CELULAR_NOTEBOOK: string = "";

  @JsonProperty({ name: "CELULAR_NOTEBOOK_JUSTIFIQUE" })
  public CELULAR_NOTEBOOK_JUSTIFIQUE: string = "";

  public static map(object: any): RelatorioDomain {
    let obj = ObjectMapper.deserialize(this, object);
    return obj;
  }
}
