import { JsonProperty, ObjectMapper } from 'json-object-mapper';

export class EmployeeClientDomain {

    constructor() { }

    @JsonProperty({name: 'matricula'})
    public matricula: string = '';

    @JsonProperty({name: 'nome'})
    public nome: string = '';

    @JsonProperty({name: 'centro_custo'})
    public centroCusto: string = '';
    
    @JsonProperty({name: 'diretoria'})
    public diretoria: string = '';
    
    @JsonProperty({name: 'gerencia'})
    public gerencia: string = '';

    @JsonProperty({name: 'cargo'})
    public cargo: string = '';      

    @JsonProperty({name: 'superior'})
    public superior: string = '';
    
    public get fullName(): string {
        return `${this.matricula} - ${this.nome}`;
    }

    public static map(object: any): EmployeeClientDomain {
        return ObjectMapper.deserialize(this, object);
    }

    public toJSON(): string {
        return <string>ObjectMapper.serialize(this);
    }
}