import { JsonProperty, ObjectMapper } from 'json-object-mapper';


export abstract class DomainBase {
    @JsonProperty({name:'id'})
    public id: number = 0;

    public toJSON(): string {
        return <string>ObjectMapper.serialize(this);
    }
}