import { environment } from '../../environments/environment';
import { JsonProperty, ObjectMapper, JsonIgnore } from 'json-object-mapper';
import { DomainBase } from "./base/base.domain";

export class ImportacaoDomain extends DomainBase {

    @JsonProperty({name: 'matricula_solicitante'})
    public matriculaSolicitante: string = '';

    @JsonProperty({name: 'solicitante'})
    public solicitante: string = '';

    @JsonProperty({name: 'arquivo'})
    public arquivo: string = '';

    @JsonProperty({name: 'nome_arquivo'})
    public nomeArquivo: string = '';

    @JsonProperty({name: 'status'})
    public status: string = '';

    @JsonProperty({name: 'data_importacao'})
    public dataImportacao: string = '';
   
    public static map(object: any): ImportacaoDomain {
        let obj = ObjectMapper.deserialize(this, object);
        return obj;
    }
}