import { JsonProperty, ObjectMapper, JsonIgnore } from "json-object-mapper";
import { DomainBase } from "./base/base.domain";
import { RespostaPerguntaDomain } from "./respostaPergunta.domain";
import { TipoDesligamentoDomain } from "./tipodeligamento.domain";
import { StatusDomain } from "./Status.Domain";

export class ExFuncionarioDomain extends DomainBase {
  @JsonProperty({ name: "matricula" })
  public matricula: string = "";

  @JsonProperty({ name: "nome" })
  public nome: string = "";

  @JsonProperty({ name: "cpf" })
  public cpf: string = "";

  @JsonProperty({ name: "cargo" })
  public cargo: string = "";

  @JsonProperty({ name: "gestor" })
  public gestor: string = "";

  @JsonProperty({ name: "empresa" })
  public empresa: string = "";

  @JsonProperty({ name: "uf" })
  public uf: string = "";

  @JsonProperty({ name: "centro_custo" })
  public centroCusto: string = "";

  @JsonProperty({ name: "matricula_solicitante" })
  public matriculaSolicitante: string = "";

  @JsonProperty({ name: "data_importacao" })
  public dataImportacao: string = "";

  @JsonProperty({ name: "justificativa" })
  public justificativa: string = "";

  @JsonProperty({ name: "tipo_importacao" })
  public tipoImportacao: string = "";

  @JsonProperty({ name: "importacao_id" })
  public importacaoId: number = 0;

  @JsonProperty({ name: "solicitante" })
  public solicitante: string = "";

  @JsonProperty({ name: "tipo_desligamento_id" })
  public tipodesligamentoid: number = 0;

  @JsonProperty({ name: "id_status_entrevista" })
  public id_status_entrevista: number = 0;

  @JsonProperty({ name: "respondido" })
  public respondido: boolean = false;

  @JsonProperty({ name: "habilitado" })
  public habilitado: boolean = false;

  @JsonProperty({ name: "data_admissao" })
  public dataAdmissao: string = "";

  @JsonProperty({ name: "data_demissao" })
  public dataDemissao: string = "";

  @JsonProperty({ name: "nome_gerente" })
  public nomeGerente: string = "";

  @JsonProperty({ name: "telefone_gerente" })
  public telefoneGerente: string = "";

  @JsonProperty({ name: "telefone_gestor" })
  public telefoneGestor: string = "";

  @JsonProperty({ name: "grupo_hierarquico" })
  public grupoHierarquico: string = "";

  @JsonProperty({ name: "local_atuacao" })
  public localAtuacao: string = "";

  @JsonProperty({ name: "tempo_empresa" })
  public tempoEmpresa: string = "";

  @JsonProperty({ name: "ds_descricao" })
  public ds_descricao: string = "";

  @JsonProperty({ name: "fl_ajustar_manual" })
  public fl_ajustar_manual: boolean = null;

  @JsonProperty({ name: "tipo_tratativa_id" })
  public tipo_tratativa_id: number = 0;

  @JsonProperty({ name: "num_chamado" })
  public num_chamado: string = "";

  @JsonProperty({ name: "just_tratativa" })
  public just_tratativa: string = "";

  @JsonProperty({ name: "justificativa_log" })
  public justificativa_log: string = "TESTE";

  @JsonProperty({ name: "status_list", type: StatusDomain })
  public status_list: StatusDomain[] = [];

  @JsonProperty({ name: "tipo_desligamento", type: TipoDesligamentoDomain })
  public tipoDesligamento: TipoDesligamentoDomain = new TipoDesligamentoDomain();

  @JsonProperty({ name: "resposta_perguntas", type: RespostaPerguntaDomain })
  public respostaPerguntas: RespostaPerguntaDomain[] = [];

  public static map(object: any): ExFuncionarioDomain {
    let obj = ObjectMapper.deserialize(this, object);
    return obj;
  }
}
