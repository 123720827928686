import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';

import { environment } from '../../environments/environment';

import { OAuthService, JwksValidationHandler, AuthConfig } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';

@Injectable()
export class AuthService {

  private pDoc: Promise<boolean>;

  constructor(private oauthService: OAuthService,
    private router: Router) {

    const config = new AuthConfig();
    config.issuer = environment.SSOUrl;
    config.clientId = environment.clientId;
    config.redirectUri = environment.redirectUri;
    config.scope = environment.ScopesSso;
    console.log(environment);
    this.oauthService.configure(config);

    // this.oauthService.redirectUri = environment.redirectUri;
    this.oauthService.requireHttps = false;
    // this.oauthService.clientId = environment.clientId;
    // this.oauthService.scope = environment.ScopesSso;
    this.oauthService.responseType = 'id_token token';
    this.oauthService.oidc = true;
    this.oauthService.setStorage(sessionStorage);
    // this.oauthService.issuer = environment.SSOUrl;

    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    this.pDoc = new Promise(resolve => {
      this.oauthService.loadDiscoveryDocument().then((d) => {
        let autenticado = this.oauthService.hasValidAccessToken();
        this.oauthService.tryLogin();

        if (!autenticado) {
          this.router.navigate(['']);
          console.log('tryLogin: false');
        }
        resolve(true);
      });
    });

    this.oauthService.events.pipe(
      filter(e => e.type === 'token_expires')
    ).subscribe(e => {
      console.debug('received token_expires event', e);
      this.oauthService.silentRefresh();
    });
  }

  isAuthenticated(): Promise<boolean> {
    return Promise.all([this.pDoc]).then(() => {
      return this.oauthService.hasValidAccessToken();
    });
  }

  getToken(): Promise<string> {
    return Promise.all([this.pDoc]).then(() => {
      return this.oauthService.getAccessToken();
    });
  }

  getClaims(): Promise<object> {
    return Promise.all([this.pDoc]).then(() => {
      let pToken = this.getProfile();

      return Promise.all([pToken]).then(() => {
        return this.oauthService.getIdentityClaims();
      });
    });
  }

  getProfile(): Promise<object> {
    return Promise.all([this.pDoc]).then(() => {
      return this.oauthService.loadUserProfile();
    });
  }

  logout(): void {
    this.oauthService.logOut();
  }

  autentica(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.isAuthenticated().then(autenticado => {

        if (autenticado) {
          resolve(true);
        } else {
          this.oauthService.initImplicitFlow();
          reject(false);
        }
      });
    });
  }

  retornarToken(): string {
    return this.oauthService.getAccessToken();
  }
}
