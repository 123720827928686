import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePage } from './pages/home/home.page';
import { AuthManager } from './_core/auth.manager';
import { ImportacaoPage } from './pages/importacao/importacao.page';
import { ImportacaoListPage } from './pages/importacao/list/importacao-list.page';
import { ImportacaoFormPage } from './pages/importacao/form/importacao-form.page';
import { ImportacaomanualPage } from './pages/importacaomanual/importacaomanual.page';
import { ImportacaomanualListPage } from './pages/importacaomanual/list/importacaomanual-list.page';
import { ImportacaomanualFormPage } from './pages/importacaomanual/form/importacaomanual-form.page';
import { EntrevistasPage } from './pages/entrevistas/entrevistas.page';
import { EntrevistasListPage } from './pages/entrevistas/list/entrevistas-list.page';
import { EntrevistasFormPage } from './pages/entrevistas/form/entrevistas-form.page';
import { RelatoriosPage } from './pages/relatorios/relatorios.page';
import { RelatoriosListPage } from './pages/relatorios/list/relatorios-list.page';

const appRoutes: Routes = [
  { path: '',
   component: HomePage,
   canActivate: [AuthManager],
   data: { resource: 'home' }
  },
  {
    path: 'importacao',
    component: ImportacaoPage,
    canActivate: [AuthManager],
    data: { resource: 'importacao' },
    children: [
        {
            path: '',
             component: ImportacaoListPage
        },
        {
            path: 'novo',
             component: ImportacaoFormPage
        },
        {
            path: 'editar/:id',
             component: ImportacaoFormPage
        }
    ]
},
{
    path: 'importacaomanual',
    component: ImportacaomanualPage,
    canActivate: [AuthManager],
    data: { resource: 'importacaomanual' },
    children: [
        {
            path: '',
             component: ImportacaomanualListPage
        },
        {
            path: 'novo',
             component: ImportacaomanualFormPage
        },
        {
            path: 'editar/:id',
             component: ImportacaomanualFormPage
        }
    ]
},
{
    path: 'exfuncionario',
    component: EntrevistasPage,
    canActivate: [AuthManager],
    data: { resource: 'exfuncionario' },
    children: [
        {
            path: '',
             component: EntrevistasListPage
        },
        {
            path: ':id',
             component: EntrevistasFormPage
        }
    ]
},
{
    path: 'relatorios',
    component: RelatoriosPage,
    canActivate: [AuthManager],
    data: { resource: 'relatorios' },
    children: [
        {
            path: '',
             component: RelatoriosListPage
        }
    ]
},
 { path: '**', redirectTo: '' }
];

export const AppRoutingModule = RouterModule.forRoot(appRoutes, { useHash: true });
// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }
