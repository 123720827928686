import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { HasClaimDirective } from './has-claim.directive';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AuthManager } from './auth.manager';
import { AuthService } from './auth.service';
import { HttpClient } from './http.cliente';

@NgModule({
    declarations: [ 
        HasClaimDirective
    ],
    imports: [
        HttpModule,
        HttpClientModule,
        OAuthModule.forRoot()
    ],
    entryComponents: [],
    providers: [
        AuthManager, 
        AuthService, 
        HttpClient
    ],
    exports: [ 
        HasClaimDirective
    ],
    bootstrap: []
})
export class CoreModule { }