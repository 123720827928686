import { ILoadingService } from './base/iloading.service';

export class LoadingService {
    private _show: ILoadingService;

    init(el: ILoadingService) {
        this._show = el;
    }

    show() {
        this._show.show();
    }

    close() {
        this._show.close();
    }
}