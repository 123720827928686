import { Component, OnInit } from '@angular/core';
import { ListAbstract } from 'src/app/services/base/list.service';
import { ImportacaoDomain } from 'src/app/domain/importacao.domain';
import { ImportacaoSelector } from 'src/app/selectors/importacao.selector';
import { ImportacaoService } from 'src/app/services/importacao.service';
import { User } from 'src/app/domain/user.domain';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from 'src/app/services/loading.service';
import { materialize } from 'rxjs/operators';
import { EmployeeClientDomain } from 'src/app/domain/EmployeeClient.domain';
import { TipoDesligamentoService } from 'src/app/services/tipodesligamento.service';
import { TipoDesligamentoSelector } from 'src/app/selectors/tipodesligamento.selector';
import { TipoDesligamentoDomain } from 'src/app/domain/tipodeligamento.domain';
import { ImportacaoManualSelector } from 'src/app/selectors/importacaoManual.selector';
import { FormGroup, FormControl } from '@angular/forms';
import { ExFuncionarioSelector } from 'src/app/selectors/exfuncionario.selector';

@Component({
  selector: 'app-importacaomanual-form',
  templateUrl: './importacaomanual-form.page.html',
  styleUrls: ['./importacaomanual-form.page.sass']
})
export class ImportacaomanualFormPage extends ListAbstract<ImportacaoDomain, ImportacaoSelector, ImportacaoService> implements OnInit {

  arquivo: string;
  nomeArquivo: string;
  tipoArquivo: string;
  user: User;
  matricula: string;
  diretoria: string;
  gerencia: string;
  solicitante: string;
  public formGroup: FormGroup;
  public typeInspectionForm: FormGroup;
  public tipoDesligamento: number;
  public tiposDesligamento: TipoDesligamentoDomain[] = [];
  public subordinados : EmployeeClientDomain[] = [];
  public subordinado: EmployeeClientDomain = new EmployeeClientDomain();
  public seletor: ExFuncionarioSelector = new ExFuncionarioSelector();  
  public matriculaSubordinado: string = '';
  public justificativa: string = '';

  constructor(
    private _importacaoService: ImportacaoService,
    private _user: UserService,
    private _tipoDesligamento: TipoDesligamentoService,
    private _toastService: ToastrService,
    private _loadingService: LoadingService
  ) {
    super(_importacaoService, new ImportacaoSelector())
    this.loading = _loadingService;
    this.toast = _toastService;

    this.formGroup = new FormGroup({
      'justificativa': new FormControl(this.justificativa),
      // 'arquivo': new FormControl(this.arquivo),
      // 'matricula': new FormControl(this.matricula)
    });

   }

  ngOnInit() {
    let selector = new ImportacaoManualSelector(); 
    this._user.getUser().then( user => {
      this.user = user;
      this.solicitante = this.user.fullName; 
      this.matricula = this.user.username;
      //this.BuscarSubordinados(this.user.username);
    });

    this._tipoDesligamento.list(new TipoDesligamentoSelector).subscribe(x =>{    
     this.tiposDesligamento = x.Data;
    });
    
  }

  BuscarSubordinados(){ 
    this._loadingService.show();
    var matricula = this.matriculaSubordinado; 
        this._importacaoService.buscarSubordinados(matricula).subscribe(x => {
            this.subordinado =x.json().data
            this._loadingService.close();
                     
            if( this.subordinado.nome == null){
                this._toastService.error('', 'Existe Entrevista cadastrada para este funcionário')
            }
        });
         
  }

  
  onFileChange(event, input: any) {
    if (event.target.files[0].size > 31684744) {
      input.value = '';
      alert('O arquivo selecionado é muito grande');
      return
    }

    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      if(file.type == 'application/pdf' || file.type == 'image/png' || file.type == 'image/bmp' || file.type == 'image/jpeg' ){
     // if(file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
     // file.type == 'application/vnd.ms-excel'){
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => { 
          this.arquivo = (reader.result as string).split(',')[1];
            this.tipoArquivo = file.type;
             this.nomeArquivo = file.name;
            // this.updateImage = true;
          };
          debugger
         }else{
        //   input.value = '';
           alert('Apenas arquivos do tipo PDF, JPEG e BMP');
         }
    }
  }

  removeArquivo() {    
    this.arquivo = null;
   }

   limparCampos() {    
    this.subordinado = new EmployeeClientDomain();
    this.justificativa = null;
    this.tipoDesligamento = 0;
    this.matriculaSubordinado = "";
   }

   enviar(){
     //debugger;
     this._loadingService.show();
     let selector = new ImportacaoManualSelector();
     selector.arquivo = this.arquivo;
     selector.nome = this.user.fullName;
     selector.matricula = this.user.username;
     selector.matriculaExFunc = this.subordinado.matricula;
     selector.tipoImportacao = 'MANUAL'; 
     selector.tipoDesligamento = this.tipoDesligamento; 
     selector.tipoArquivo = this.tipoArquivo;
     selector.nomeArquivo = this.nomeArquivo;
     selector.justificativa = this.justificativa;    
    this._importacaoService.importarManual(selector)
    .subscribe(x => {
      this._loadingService.close();
      this.removeArquivo();
      this.limparCampos();
       this._toastService.success('Sucesso', 'Ação realizada com sucesso');
    });
   }
   

  //  selectDiretoriaGerencia(){
  //    var a = this.subordinado
  //    //var matricula = a.matricula;
  //    this._importacaoService.buscarDiretoriaGerencia(this.subordinado.matricula).subscribe(x => {
  //     //this.subordinados =x.json().data;
  //     //this.matriculaSubordinado = x.
  //     // this.diretoria = x.data;
  //     // this.gerencia = x.data;
  //   });
     
  //    debugger
  //  }

}

