import { LoadingService } from "./../loading.service";
import { DomainBase } from "../../domain/base/base.domain";
import { SelectorBase } from "../../selectors/base/base.selector";
import { ServiceBase } from "./base.service";
import { ToastrService } from "ngx-toastr";

export abstract class ListAbstract<
  E extends DomainBase,
  T extends SelectorBase,
  S extends ServiceBase<T, E>
> {
  private _service: S;
  private _seletor: T;
  private _totalItems: number;

  private _toast: ToastrService;
  private _loading: LoadingService;

  private _list: E[];

  constructor(service: S, seletor: T) {
    this.Seletor = seletor;
    this._service = service;
    this._totalItems = 0;
  }

  set toast(toast: ToastrService) {
    this._toast = toast;
  }

  set loading(loading: LoadingService) {
    this._loading = loading;
  }

  get Seletor(): T {
    return this._seletor;
  }

  set Seletor(seletor: T) {
    this._seletor = seletor;
  }

  get Service(): S {
    return this._service;
  }

  get TotalItens(): number {
    return this._totalItems;
  }

  get List(): E[] {
    return this._list;
  }

  set List(list: E[]) {
    this._list = list;
  }

  public query() {
    this._loading.show();

    try {
      this.Seletor.toOrderby();
      this.Service.list(this.Seletor).subscribe(
        data => {
          this._totalItems = data.TotalRows;
          this._list = data.Data;
          this._loading.close();
        },
        err => {
          this._loading.close();
          this.toast.error("Ocorreu algum erro na requisião", "Ops");
        }
      );
    } catch {
      this._loading.close();
      this.toast.error("Ocorreu algum erro na requisião", "Ops");
    }
  }

  setOrder(field: string) {
    this.Seletor.orderBy = field;
    this.Seletor.orderByOrder =
      this.Seletor.orderByOrder == "ASC" ? "DESC" : "ASC";
    this.query();
  }

  changePage(e) {
    this.Seletor.Page = e.page;
    this.query();
  }
}
