import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { ImportacaoSelector } from 'src/app/selectors/importacao.selector';
import { ImportacaoService } from 'src/app/services/importacao.service';
import { ImportacaoDomain } from 'src/app/domain/importacao.domain';
import { ListAbstract } from 'src/app/services/base/list.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/domain/user.domain';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-form',
  templateUrl: './importacao-form.page.html',
  styleUrls: ['./importacao-form.page.sass']
})
export class ImportacaoFormPage extends ListAbstract<ImportacaoDomain, ImportacaoSelector, ImportacaoService>
 implements OnInit {

  arquivo: string;
  nomeArquivo: string;
  user: User;
  matricula: string;
  solicitante: string;
  importarCSVEndPoint = "importacao/importarArquivoCSV";

  constructor(
    private _importacaoService: ImportacaoService,
    private _user: UserService,
    private _toastService: ToastrService,
    private _loadingService: LoadingService
  )
  {
    super(_importacaoService, new ImportacaoSelector())
    this.loading = _loadingService;
    this.toast = _toastService;
  }

  ngOnInit() {
    this._loadingService.show();
    let selector = new ImportacaoSelector();
    this._user.getUser().then( user => {
      this.user = user;
      this.solicitante = this.user.fullName;
      this.matricula = this.user.username;
    });
    this._loadingService.close();
  }

  onFileChange(event, input: any) {
    if (event.target.files[0].size > 31684744) {
      input.value = '';
      alert('O arquivo selecionado é muito grande');
      return
    }

    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      //if(file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      //file.type == 'application/vnd.ms-excel'){
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
          this.arquivo = (reader.result as string).split(',')[1];
            // this.treatment.arquivoType = file.type;
             this.nomeArquivo = file.name;
            // this.updateImage = true;
          };
        /*}else{
          input.value = '';
          alert('Apenas arquivos do tipo EXCEL');
        }*/
    }
  }

  enviar(){
     this._loadingService.show();

     let selector = new ImportacaoSelector();
      selector.arquivo = this.arquivo;
      selector.nomeArquivo = this.nomeArquivo;
      selector.matriculaSolicitante = this.user.username;
     this._importacaoService.importar(selector)
     .subscribe(x => {
       this._loadingService.close();
       this.removeArquivo();
       this._loadingService.close();
        this._toastService.success('Sucesso', 'Ação realizada com sucesso');
     });
  }

  removeArquivo() {
   this.arquivo = null;
  }

}
