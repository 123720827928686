import { NgModule } from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";
import { AppRoutingModule } from "../../app-routing.module";
import { ImportacaoPage } from './importacao.page';
import { ImportacaoListPage } from './list/importacao-list.page';
import { ImportacaoFormPage } from './form/importacao-form.page';
import { UserService } from 'src/app/services/user.service';
import { ImportacaoService } from 'src/app/services/importacao.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule, PaginationModule, BsDatepickerModule } from 'ngx-bootstrap';
// import { Angular5Csv } from 'angular5-csv/Angular5-csv';
import { LoadingModule } from 'ngx-loading';
import localePt from '@angular/common/locales/pt';
import { NgxPaginationModule } from 'ngx-pagination';
import { UploadComponent } from '../../components/upload/upload.component';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
    declarations: [
        ImportacaoPage,
        ImportacaoListPage,
        ImportacaoFormPage,
        UploadComponent
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        //Angular5Csv,
        NgxPaginationModule,
        LoadingModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        BsDatepickerModule.forRoot()
    ],
    exports: [
        ImportacaoPage,
        ImportacaoListPage,
        ImportacaoFormPage,
        UploadComponent
    ],
    entryComponents: [UploadComponent],
    providers: [
        UserService,
        ImportacaoService
    ],
})
export class ImportacaoModule { }
