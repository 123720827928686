import { JwtHelper } from './base/jwt-helper.service';
import { User } from './../domain/user.domain';
import { AuthService } from './../_core/auth.service';
import { HttpClient } from './../_core/http.cliente';
import { Injectable } from '@angular/core';

@Injectable()
export class UserService {
    constructor(
        private _http: HttpClient,
        private _auth: AuthService
    ) { }

    getUser() {
        return this._auth.isAuthenticated().then(isAuthenticated => {
            if (isAuthenticated) {
                return new Promise<User>(resolve => {
                    this._auth.getProfile().then(x => {
                        resolve(User.map(x));
                    });
                });
            }
        });
    }

    getTeams(): string[] {
        let token = sessionStorage.getItem('access_token');
        return User.map(JwtHelper.decodeToken(token)).getTeams();
    }

}