import { JsonProperty, ObjectMapper } from 'json-object-mapper';

export class SelectorBase {

    @JsonProperty({name: 'Id'})
    public id: number;

    @JsonProperty({name: 'Pagina'})
    public Page: number = 1;

    @JsonProperty({name: 'RegistroPorPagina'})
    public rowsForPage: number = 10;

    @JsonProperty({name: 'OrderBy'})
    public orderBy: string = '';

    @JsonProperty({name: 'OrderByOrder'})
    public orderByOrder: string = '';

    public toJSON(): string {
        return <string>ObjectMapper.serialize(this);
    }
    
    public toOrderby(){
        if(this.orderByOrder == "DESC")
        {
            this.orderByOrder = "ASC";
        }else{
            this.orderByOrder = "DESC";   
        }
    }

    public removeDuplicate(arr){
        var tmp = [];
        for(var i = 0; i < arr.length; i++){
            if(tmp.indexOf(arr[i]) == -1){
            tmp.push(arr[i]);
            }
        }
        return tmp;
    }
}