import { environment } from '../../environments/environment';
import { JsonProperty, ObjectMapper, JsonIgnore } from 'json-object-mapper';
import { DomainBase } from "./base/base.domain";

export class StatusDomain extends DomainBase {

    @JsonProperty({name: 'ds_descricao'})
    public descricao: string = '';

    @JsonProperty({name: 'fl_excluido'})
    public excluido: boolean = false;

    @JsonProperty({name: 'fl_ativo'})
    public ativo: boolean = false;
   
    public static map(object: any): StatusDomain {
        let obj = ObjectMapper.deserialize(this, object);
        return obj;
    }
}