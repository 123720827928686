import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-importacaomanual',
  templateUrl: './importacaomanual.page.html',
  styleUrls: ['./importacaomanual.page.sass']
})
export class ImportacaomanualPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
