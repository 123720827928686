import { Component, OnInit } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-entrevistas',
  templateUrl: './entrevistas.page.html',
  styleUrls: ['./entrevistas.page.sass']
})
export class EntrevistasPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
