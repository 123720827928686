import { environment } from '../../environments/environment';
import { JsonProperty, ObjectMapper, JsonIgnore } from 'json-object-mapper';
import { DomainBase } from "./base/base.domain";

export class PerguntaDomain extends DomainBase {

    @JsonProperty({name: 'descricao_pergunta'})
    public descricao: string = '';

    @JsonProperty({name: 'data'})
    public data: string = '';

    @JsonProperty({name: 'ativo'})
    public ativo: boolean = false;

    @JsonProperty({name: 'id_tipo_pergunta'})
    public idTipoPergunta: number = 0;

    public static map(object: any): PerguntaDomain {
        let obj = ObjectMapper.deserialize(this, object);
        return obj;
    }
}