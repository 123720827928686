import { JsonProperty, ObjectMapper } from 'json-object-mapper';

export class User {

    constructor() { }

    @JsonProperty({name: 'sub'})
    public id: string = '';

    //@JsonProperty({name: 'auth.Perfil'})
    @JsonProperty({name: 'entrevista.perfil'})
    public profile: string = '';

    @JsonProperty({name: 'user_name'})
    public username: string = '';
    
    @JsonProperty({name: 'first_name'})
    public firstName: string = '';
    
    @JsonProperty({name: 'last_name'})
    public lastName: string = '';

    @JsonProperty({name: 'app.Acesso'})
    public access: string = '';

    @JsonProperty({name: 'entrevista.Equipe'})
    public teams: string = '';

    @JsonProperty()
    public claims: object;

    public getTeams(): string[] {
        return this.teams.split(',');
    }

    public get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public static map(object: any): User {
        return ObjectMapper.deserialize(this, object);
    }

    public toJSON(): string {
        return <string>ObjectMapper.serialize(this);
    }
}