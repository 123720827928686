import { Component } from '@angular/core';
import { LoadingService } from './services/loading.service';
import { ILoadingService } from './services/base/iloading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements ILoadingService {
  title = 'app';
  loading: boolean = false;

  constructor(private _loadingService: LoadingService) {
    _loadingService.init(this);
  }
  
  show(): void {
    setTimeout(() => {
      this.loading = true;
    }, 100);
  }
  
  close(): void {
    setTimeout(() => {
      this.loading = false;
    }, 100);
  }
}
