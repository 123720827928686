import { SelectorBase } from './../selectors/base/base.selector';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DomainBase } from "../domain/base/base.domain";
import { ServiceBase } from '../services/base/base.service';

export abstract class FormAbstract<T extends DomainBase, X extends ServiceBase<SelectorBase, T>>  {

    private _service: X;
    private _totalItems: number;
    private _router: Router;
    private _activeRoute: ActivatedRoute;
    private _toaster: ToastrService;

    private _object: T;
    private _id: number;

    constructor(service: X, activeRoute: ActivatedRoute, router: Router, toaster: ToastrService) {
        this._service = service;
        this._activeRoute = activeRoute;
        this._router = router;
        this._toaster = toaster;
    }

    get Id(): number {
        return this._id;
    }

    get Service(): X {
        return this._service;
    }

    get Object(): T {
        return this._object;
    }

    get Router(): Router {
        return this._router;
    }

    get Toaster(): ToastrService {
        return this._toaster;
    }

    get ActiveRoute(): ActivatedRoute {
        return this._activeRoute;
    }

    set Object(object: T) {
        this._object = object;
    }

    query() {
        this._activeRoute.params.subscribe((params: Params) => {
            let id = params['id'];

            if (id == undefined) {
                this._id = null;
                this.Object = ({} as T);
            } else {
                this._id = id;
                this.Service.get(this._id).subscribe(data => {
                    this.Object = data.Data;
                });
            }
        });
    }

    save() {
        this.Service.save(this.Id, this.Object).subscribe(res => {
            this._toaster.show('success', 'Operação concluída');
            this._router.navigate(['../'], { relativeTo: this._activeRoute });
        });
    }
}